@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf") format("truetype"),
url("../fonts/flaticon.woff") format("woff"),
url("../fonts/flaticon.woff2") format("woff2"),
url("../fonts/flaticon.eot#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-play-button:before {
    content: "\f101";
}
.flaticon-play-button-arrowhead:before {
    content: "\f102";
}
.flaticon-quotation-mark:before {
    content: "\f103";
}
.flaticon-minus-sign:before {
    content: "\f104";
}
.flaticon-plus-positive-add-mathematical-symbol:before {
    content: "\f105";
}
.flaticon-chat-comment-oval-speech-bubble-with-text-lines:before {
    content: "\f106";
}
.flaticon-close-button:before {
    content: "\f107";
}
.flaticon-tick-inside-circle:before {
    content: "\f108";
}
.flaticon-pdf-file:before {
    content: "\f109";
}
.flaticon-share:before {
    content: "\f10a";
}
.flaticon-right-arrow:before {
    content: "\f10b";
}
.flaticon-phone-call:before {
    content: "\f10c";
}
.flaticon-left-arrow:before {
    content: "\f10d";
}
.flaticon-right-arrow-1:before {
    content: "\f10e";
}
.flaticon-search:before {
    content: "\f10f";
}
.flaticon-telephone:before {
    content: "\f110";
}
.flaticon-apartment:before {
    content: "\f111";
}
.flaticon-credit-card:before {
    content: "\f112";
}
.flaticon-left-arrow-1:before {
    content: "\f113";
}
.flaticon-right-arrow-2:before {
    content: "\f114";
}
.flaticon-secure-shield:before {
    content: "\f115";
}
.flaticon-tick:before {
    content: "\f116";
}
.flaticon-play:before {
    content: "\f117";
}
.flaticon-play-button-1:before {
    content: "\f118";
}
.flaticon-call:before {
    content: "\f119";
}
.flaticon-pin:before {
    content: "\f11a";
}
.flaticon-arrow:before {
    content: "\f11b";
}
.flaticon-hand-gesture:before {
    content: "\f11c";
}
.flaticon-email:before {
    content: "\f11d";
}
.flaticon-email-1:before {
    content: "\f11e";
}
.flaticon-down-arrow:before {
    content: "\f11f";
}
.flaticon-done:before {
    content: "\f120";
}
.flaticon-invisible:before {
    content: "\f121";
}
.flaticon-up-arrow:before {
    content: "\f122";
}
.flaticon-down-arrow-1:before {
    content: "\f123";
}
.flaticon-customer:before {
    content: "\f124";
}
.flaticon-checked:before {
    content: "\f125";
}
.flaticon-next:before {
    content: "\f126";
}
.flaticon-right-arrow-3:before {
    content: "\f127";
}
.flaticon-play-1:before {
    content: "\f128";
}
.flaticon-email-2:before {
    content: "\f129";
}
.flaticon-calendar:before {
    content: "\f12a";
}
.flaticon-send:before {
    content: "\f12b";
}
.flaticon-support:before {
    content: "\f12c";
}
.flaticon-left-arrow-2:before {
    content: "\f12d";
}
.flaticon-time:before {
    content: "\f12e";
}
.flaticon-planet-earth:before {
    content: "\f12f";
}
.flaticon-airplane:before {
    content: "\f130";
}
.flaticon-call-1:before {
    content: "\f131";
}
.flaticon-linkedin:before {
    content: "\f132";
}
.flaticon-user:before {
    content: "\f133";
}
.flaticon-user-1:before {
    content: "\f134";
}
.flaticon-debit-card:before {
    content: "\f135";
}
.flaticon-file:before {
    content: "\f136";
}
.flaticon-quote:before {
    content: "\f137";
}
.flaticon-quote-1:before {
    content: "\f138";
}
.flaticon-shopping-cart:before {
    content: "\f139";
}
.flaticon-payment-method:before {
    content: "\f13a";
}
.flaticon-exit:before {
    content: "\f13b";
}
.flaticon-briefcase:before {
    content: "\f13c";
}
.flaticon-price-tag:before {
    content: "\f13d";
}
.flaticon-square:before {
    content: "\f13e";
}
.flaticon-check:before {
    content: "\f13f";
}
.flaticon-close:before {
    content: "\f140";
}
.flaticon-share-1:before {
    content: "\f141";
}
.flaticon-star:before {
    content: "\f142";
}
.flaticon-bath-tub:before {
    content: "\f143";
}
.flaticon-user-2:before {
    content: "\f144";
}
.flaticon-home:before {
    content: "\f145";
}
.flaticon-bubble-chat:before {
    content: "\f146";
}
.flaticon-envelope:before {
    content: "\f147";
}
.flaticon-facebook:before {
    content: "\f148";
}
.flaticon-google-plus:before {
    content: "\f149";
}
.flaticon-instagram:before {
    content: "\f14a";
}
.flaticon-linkedin-1:before {
    content: "\f14b";
}
.flaticon-twitter:before {
    content: "\f14c";
}
.flaticon-briefcase-1:before {
    content: "\f14d";
}
.flaticon-speech-bubble:before {
    content: "\f14e";
}
.flaticon-bell:before {
    content: "\f14f";
}
.flaticon-email-3:before {
    content: "\f150";
}
.flaticon-plane:before {
    content: "\f151";
}
.flaticon-globe:before {
    content: "\f152";
}
.flaticon-search-1:before {
    content: "\f153";
}
.flaticon-quote-2:before {
    content: "\f154";
}
.flaticon-location:before {
    content: "\f155";
}
.flaticon-calendar-1:before {
    content: "\f156";
}
.flaticon-double-bed:before {
    content: "\f157";
}
.flaticon-love:before {
    content: "\f158";
}
.flaticon-send-1:before {
    content: "\f159";
}
.flaticon-remove:before {
    content: "\f15a";
}
.flaticon-sign-out:before {
    content: "\f15b";
}
.flaticon-left-chevron:before {
    content: "\f15c";
}
.flaticon-search-2:before {
    content: "\f15d";
}
.flaticon-plus:before {
    content: "\f15e";
}
.flaticon-right-arrow-4:before {
    content: "\f15f";
}
.flaticon-map:before {
    content: "\f160";
}
.flaticon-back:before {
    content: "\f161";
}
.flaticon-next-1:before {
    content: "\f162";
}
.flaticon-up-right-arrow:before {
    content: "\f163";
}
.flaticon-driving-license:before {
    content: "\f164";
}
.flaticon-left:before {
    content: "\f165";
}
.flaticon-left-arrow-3:before {
    content: "\f166";
}
.flaticon-quotes:before {
    content: "\f167";
}
.flaticon-heart:before {
    content: "\f168";
}
.flaticon-call-2:before {
    content: "\f169";
}
.flaticon-email-4:before {
    content: "\f16a";
}
.flaticon-office-building:before {
    content: "\f16b";
}
.flaticon-deal:before {
    content: "\f16c";
}
.flaticon-user-avatar:before {
    content: "\f16d";
}
.flaticon-up-arrow-1:before {
    content: "\f16e";
}
.flaticon-customer-review:before {
    content: "\f16f";
}
.flaticon-quality-control:before {
    content: "\f170";
}
.flaticon-arrow-left:before {
    content: "\f171";
}
