@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&family=Poppins:wght@300;400;500;600;700;800&display=swap");
/*---------------------------------
            General Styles 
-----------------------------------*/
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:focus,
button:focus,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

a,
button,
input[type="submit"] {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: #000;
  text-transform: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover,
a :focus {
  text-decoration: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #000;
}

a:focus {
  outline: 0 solid;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.3;
  color: #000;
  font-family: "Poppins", sans-serif;
}

body {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #666666;
  overflow-x: hidden;
}

p {
  font-size: 16px;
  line-height: 1.8;
  color: #666666;
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../images/down-arrow.png);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 94% 50%;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-130 {
  padding-bottom: 130px;
}

.ptb-10 {
  padding-bottom: 10px;
  padding-bottom: 10px;
}

.ptb-20 {
  padding-bottom: 20px;
  padding-bottom: 20px;
}

.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ptb-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mmt-10 {
  margin-top: -10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mtb-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.z-0 {
  z-index: 0;
  position: relative;
}

#message {
  padding: 0;
  margin: 0;
}

.help-block {
  color: #E31C25;
}

.list-style {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-style li {
  list-style: none;
}

.page-wrapper {
  overflow-x: hidden;
}

/*---------------------------------
          Mixins & Animation CSS
-----------------------------------*/
.btn.fb span, .btn.twitter span, .btn.linkedin span, .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .social-profile.style1 li a, .social-profile.style2 li a, #signIn .modal-content .modal-header .btn-close,
#signUp .modal-content .modal-header .btn-close, .counter-card-wrap .counter-card .counter-icon, .contact-item .contact-icon, .property-thumb-item, .agent-info-box .agent-name-wrap, .hero-wrap.style3 .hero-content .client-feedback, .property-card .property-info .property-status-wrap, .testimonial-card.style2 .client-info-wrap, .testimonial-card.style3 .client-info-wrap, .counter-card-wrap .counter-card, .post-navigation, .wp-block-quote, .agent-details-wrap .agent-info-wrap .agent-social, .select-lang .navbar-language .dropdown-toggle, .property-card .property-info .property-metainfo, .testimonial-card .quote-wrap .client-info-wrap, .owl-carousel .owl-nav, .pp-post-item, .hero-wrap.style3 .hero-content .property-search-form, .hero-wrap.style3 .hero-content .property-search-form .form-group-wrap, .search-property .property-search-form, .counter-card-wrap, .newsletter-form, .post-author, .comment-item-wrap .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.btn.fb span, .btn.twitter span, .btn.linkedin span, .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .social-profile.style1 li a, .social-profile.style2 li a, #signIn .modal-content .modal-header .btn-close,
#signUp .modal-content .modal-header .btn-close, .counter-card-wrap .counter-card .counter-icon, .contact-item .contact-icon {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.property-thumb-item, .agent-info-box .agent-name-wrap, .hero-wrap.style3 .hero-content .client-feedback, .property-card .property-info .property-status-wrap, .testimonial-card.style2 .client-info-wrap, .testimonial-card.style3 .client-info-wrap, .counter-card-wrap .counter-card, .post-navigation, .wp-block-quote, .agent-details-wrap .agent-info-wrap .agent-social, .select-lang .navbar-language .dropdown-toggle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.property-card .property-info .property-status-wrap, .property-card .property-info .property-metainfo, .testimonial-card .quote-wrap .client-info-wrap, .post-navigation {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hero-wrap.style3 .hero-content .client-feedback {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 85px;
  width: 85px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 #fff;
  -ms-box-shadow: 0 0 0 0 #fff;
  -o-box-shadow: 0 0 0 0 #fff;
  box-shadow: 0 0 0 0 #fff;
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.play-now {
  position: relative;
  height: 85px;
  width: 85px;
  text-align: center;
  line-height: 92px;
  background-color: #fff;
  color: #fff;
  z-index: 1;
  font-size: 28px;
  padding-left: 5px;
  display: block;
  -webkit-transform-origin: center;
  transform-origin: center center;
  border-radius: 50%;
  margin: 0 auto;
}

.play-now i {
  color: #0ABAB5;
  font-size: 24px;
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*---------------------------------
     Back To Top Button CSS
-----------------------------------*/
.back-to-top {
  font-size: 24px;
  width: 45px;
  height: 45px;
  line-height: 42px;
  text-align: center;
  display: none;
  position: fixed;
  bottom: 230px;
  right: 20px;
  z-index: 999;
  border-radius: 5px;
  background: #0ABAB5;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.back-to-top.open {
  bottom: 30px;
}

.back-to-top i {
  color: #fff;
}

/*---------------------------------
     Preloader CSS
-----------------------------------*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 20000;
  overflow-x: hidden !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.loader div {
  width: 12px;
  height: 12px;
  margin: 0 10px 0 0;
  border-radius: 50px;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  display: inline-block;
  -webkit-animation: bouncing 1.4s linear infinite;
          animation: bouncing 1.4s linear infinite;
}

.loader div:last-child {
  margin: 0;
}

.loader div:nth-child(1) {
  background: rgba(236, 51, 35, 0.9);
}

.loader div:nth-child(2) {
  background: rgba(236, 51, 35, 0.7);
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.loader div:nth-child(3) {
  background: rgba(236, 51, 35, 0.5);
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

@-webkit-keyframes bouncing {
  0%, 100% {
    -webkit-transform: translateY(0) scale(1, 1);
            transform: translateY(0) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: translateY(80px) scale(1, 1);
            transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateY(80px) scale(1.5, 0.5);
            transform: translateY(80px) scale(1.5, 0.5);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  55% {
    -webkit-transform: translateY(80px) scale(1, 1);
            transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@keyframes bouncing {
  0%, 100% {
    -webkit-transform: translateY(0) scale(1, 1);
            transform: translateY(0) scale(1, 1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: translateY(80px) scale(1, 1);
            transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateY(80px) scale(1.5, 0.5);
            transform: translateY(80px) scale(1.5, 0.5);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  55% {
    -webkit-transform: translateY(80px) scale(1, 1);
            transform: translateY(80px) scale(1, 1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/*---------------------------------
       Button & Link CSS
----------------------------------*/

.btn {
    display: inline-block;
    line-height: 26px;
    font-weight: 400;
    border-radius: 5px;
    text-align: center;
    -webkit-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    position: relative;
    z-index: 1;
    border: 1px solid transparent;
    padding: 10px 17px 10px;
    border-radius: 5px;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
}

.btn i {
  position: relative;
  top: 3px;
  font-size: 16px;
  margin-left: 7px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  color: #fff;
}

.btn:hover:after, .btn:hover:before {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.btn.style1 {
  background-color: #0ABAB5;
}
.btn.style1:after, .btn.style1:before {
  background: #111;
}

.btn.style2 {
  background-color: #0ABAB5;
  color: #fff;
}

.btn.style2:after, .btn.style2:before {
  background: #111;
}

.btn.style2:hover {
  color: #fff;
}

.btn.style3 {
  background-color: transparent;
  border: 1px solid #0ABAB5;
  color: #0ABAB5;
}

.btn.style3:after, .btn.style3:before {
  background: #111;
}

.btn.style3:hover {
  color: #fff;
  border-color: transparent;
}

.btn.style4 {
  background-color: #fff;
  color: #0ABAB5;
}

.btn.style4:after, .btn.style4:before {
  background: #111;
}

.btn.style4:hover {
  color: #fff;
}

.btn.style5 {
  background-color: transparent;
  border: 1px solid #0ABAB5;
  color: #0ABAB5;
}

.btn.style5:after, .btn.style5:before {
  background: #111;
}

.btn.style5:hover {
  color: #fff;
}

.btn.style6 {
  background-color: #fff;
  color: #0ABAB5;
}

.btn.style6:after, .btn.style6:before {
  background: #111;
}

.btn.style6:hover {
  color: #fff;
}

.btn.fb, .btn.twitter, .btn.linkedin {
  display: block;
  width: 100%;
  text-align: left;
}

.btn.fb span, .btn.twitter span, .btn.linkedin span {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  text-align: center;
}

.btn.fb span i, .btn.twitter span i, .btn.linkedin span i {
  font-size: 22px;
  position: relative;
  top: -1px;
  margin: 0;
}

.btn.fb {
  background-color: #1976D3;
}

.btn.fb span {
  background-color: #156ABD;
}

.btn.linkedin {
  background-color: #FF641A;
}

.btn.linkedin span {
  background-color: #E55A17;
}

.btn.twitter {
  background-color: #03A9F5;
}

.btn.twitter span {
  background-color: #0398DC;
}

.link {
  font-weight: 600;
  line-height: 26px;
  display: inline-block;
  position: relative;
  color: #0ABAB5;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background: #0ABAB5;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.link i {
  position: relative;
  top: 3px;
  margin-left: 5px;
  font-size: 14px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.link:hover {
  color: #0ABAB5;
}

.link:hover:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.link:hover i {
  margin-left: 10px;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.owl-carousel .owl-nav button.owl-prev i, .owl-carousel .owl-nav button.owl-next i {
  font-size: 18px;
  line-height: 0.8;
  display: inline-block;
  position: relative;
  top: 1px;
  color: #0abab5;
  margin: 0 auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.owl-carousel .owl-nav button.owl-prev:hover, .owl-carousel .owl-nav button.owl-next:hover {
  background-color: #0ABAB5;
  border-color: transparent;
}

.owl-carousel .owl-nav button.owl-prev:hover i, .owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

.owl-carousel .owl-dots button.owl-dot {
  display: inline-block;
  margin: 0 6px;
  position: relative;
  bottom: -5px;
}

.owl-carousel .owl-dots button.owl-dot span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.owl-carousel .owl-dots button.owl-dot span:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}

.owl-carousel .owl-dots button.owl-dot.active span {
  border-color: #0ABAB5;
}

.owl-carousel .owl-dots button.owl-dot.active span:after {
  background-color: #0ABAB5;
  width: 7px;
  height: 7px;
}

/*--------------------------------------
    Section Title,Overlay & Shape CSS
----------------------------------------*/
.section-title,
.content-title {
  position: relative;
}

.section-title h2,
.content-title h2 {
  font-size: 40px;
  font-weight: 700;
  margin: 0;
}

.section-title p,
.content-title p {
  margin: 12px 0 0;
}

.section-title span,
.content-title span {
  display: inline-block;
  font-weight: 600;
  margin: 0 0 10px;
  position: relative;
  z-index: 1;
  line-height: 1;
}

.section-title.style1 span,
.content-title.style1 span {
  color: #0ABAB5;
}

.section-title.style2 span,
.content-title.style2 span {
  letter-spacing: 8px;
  color: #0ABAB5;
}

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-athens {
  background-color: #F3F4F6;
}

.bg-albastor {
  background-color: #f9f9f9;
}

.bg-seashell {
  background-color: #f9f9f9;
}

.bg-reso {
  background-color: #0ABAB5;
}

.body_overlay {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.body_overlay.open {
  visibility: visible;
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-75 {
  opacity: 0.75;
}

.op-8 {
  opacity: 0.8;
}

.op-85 {
  opacity: 0.85;
}

.op-9 {
  opacity: 0.9;
}

.op-95 {
  opacity: 0.95;
}

.op-10 {
  opacity: 1;
}

.bg-f {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ddd;
}

.pos-rel {
  position: relative;
  z-index: 1;
}

.bounce {
  -webkit-animation: float 1500ms infinite ease-in-out;
  animation: float 1500ms infinite ease-in-out;
}

.moveHorizontal {
  -webkit-animation: moveHorizontal 3000ms infinite ease-in-out;
  animation: moveHorizontal 3000ms infinite ease-in-out;
}

.moveVertical {
  -webkit-animation: moveVertical 3000ms infinite ease-in-out;
  animation: moveVertical 3000ms infinite ease-in-out;
}

.moveHorizontal_reverse {
  -webkit-animation: moveHorizontal_reverse 3000ms infinite ease-in-out;
  animation: moveHorizontal_reverse 3000ms infinite ease-in-out;
}

.animationFramesTwo {
  -webkit-animation: animationFramesTwo 20000ms infinite ease-in-out;
  animation: animationFramesTwo 20000ms infinite ease-in-out;
}

.waving_left {
  -webkit-animation: waving_left 7s infinite linear;
  animation: waving_left 7s infinite linear;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.waving_right {
  -webkit-animation: waving_right 7s infinite linear;
  animation: waving_right 7s infinite linear;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rotate {
  -webkit-animation: rotation 20s infinite linear;
  animation: rotation 20s infinite linear;
}

@-webkit-keyframes waving_left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(3deg);
  }
  50% {
    -webkit-transform: rotate(6deg);
  }
  75% {
    -webkit-transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes waving_left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(3deg);
  }
  50% {
    -webkit-transform: rotate(6deg);
  }
  75% {
    -webkit-transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes waving_right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(-3deg);
  }
  50% {
    -webkit-transform: rotate(-6deg);
  }
  75% {
    -webkit-transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes waving_right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(-3deg);
  }
  50% {
    -webkit-transform: rotate(-6deg);
  }
  75% {
    -webkit-transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes moveHorizontal {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveHorizontal {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveHorizontal_reverse {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveHorizontal_reverse {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveVertical {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes moveVertical {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/*-------------------------------
     Social Link  CSS
-------------------------------*/
.social-profile li {
  display: inline-block;
  margin: 0 4px;
}

.social-profile li:first-child {
  margin-left: 0;
}

.social-profile li:last-child {
  margin-right: 0;
}

.social-profile li:last-child {
  margin-right: 0;
}

.social-profile li a {
  display: block;
  text-align: center;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-profile li a i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  line-height: 0.8;
}

.social-profile li a:hover {
  border-color: transparent;
  color: #0ABAB5;
}

.social-profile.style1 li a, .social-profile.style2 li a {
  width: 35px;
  height: 35px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-profile.style1 li a {
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.social-profile.style1 li a i {
  color: #0ABAB5;
}

.social-profile.style1 li a:hover {
  background-color: #0ABAB5;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.social-profile.style1 li a:hover i {
  color: #fff;
}

.social-profile.style2 li a {
  background: #f9f9f9;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.01);
          box-shadow: 0 5px 8px rgba(0, 0, 0, 0.01);
}

.social-profile.style2 li a i {
  color: #0ABAB5;
}

.social-profile.style2 li a:hover {
  background-color: #0ABAB5;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.social-profile.style2 li a:hover i {
  color: #fff;
}

.social-profile.style3 li a {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.social-profile.style3 li a i {
  color: #0ABAB5;
}

.social-profile.style3 li a:hover {
  background-color: #0ABAB5;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.social-profile.style3 li a:hover i {
  color: #fff;
}

/*-------------------------------
        Pagination  CSS
-------------------------------*/
.page-nav {
  text-align: center;
}

.page-nav li {
  margin: 0 5px;
  display: inline-block;
}

.page-nav li a {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 44px;
  font-weight: 400;
  opacity: 0.7;
  color: #666666;
  background: #f9f9f9;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-nav li a i {
  font-size: 14px;
  line-height: 42px;
  position: relative;
  top: 1px;
  color: #0ABAB5;
  margin: 0 auto;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-nav li a.active, .page-nav li a:hover {
  color: #fff;
  opacity: 1;
  background-color: #0ABAB5;
  border-color: transparent;
}

.page-nav li a.active i, .page-nav li a:hover i {
  color: #fff;
}

.btn.fb span, .btn.twitter span, .btn.linkedin span, .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .social-profile.style1 li a, .social-profile.style2 li a, #signIn .modal-content .modal-header .btn-close,
#signUp .modal-content .modal-header .btn-close, .counter-card-wrap .counter-card .counter-icon, .contact-item .contact-icon, .property-thumb-item, .agent-info-box .agent-name-wrap, .hero-wrap.style3 .hero-content .client-feedback, .property-card .property-info .property-status-wrap, .testimonial-card.style2 .client-info-wrap, .testimonial-card.style3 .client-info-wrap, .counter-card-wrap .counter-card, .post-navigation, .wp-block-quote, .agent-details-wrap .agent-info-wrap .agent-social, .select-lang .navbar-language .dropdown-toggle, .property-card .property-info .property-metainfo, .testimonial-card .quote-wrap .client-info-wrap, .owl-carousel .owl-nav, .pp-post-item, .hero-wrap.style3 .hero-content .property-search-form, .hero-wrap.style3 .hero-content .property-search-form .form-group-wrap, .search-property .property-search-form, .counter-card-wrap, .newsletter-form, .post-author, .comment-item-wrap .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.btn.fb span, .btn.twitter span, .btn.linkedin span, .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .social-profile.style1 li a, .social-profile.style2 li a, #signIn .modal-content .modal-header .btn-close,
#signUp .modal-content .modal-header .btn-close, .counter-card-wrap .counter-card .counter-icon, .contact-item .contact-icon {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.property-thumb-item, .agent-info-box .agent-name-wrap, .hero-wrap.style3 .hero-content .client-feedback, .property-card .property-info .property-status-wrap, .testimonial-card.style2 .client-info-wrap, .testimonial-card.style3 .client-info-wrap, .counter-card-wrap .counter-card, .post-navigation, .wp-block-quote, .agent-details-wrap .agent-info-wrap .agent-social, .select-lang .navbar-language .dropdown-toggle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

/*---------------------------------
        Header CSS 
-----------------------------------*/
.header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: 0.4s;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    background: #fff;
}

.header-wrap.sticky {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #fff;
}

.header-wrap.sticky.style1 .header-bottom {
  background-color: transparent;
}

.header-wrap .header-btn  {
  border: 1px solid transparent;
}

.header-wrap .header-btn .btn.style3 {
  border-color: #0ABAB5;
  margin-right: 15px;
}

.header-wrap .header-btn .btn:hover {
  border-color: transparent;
}

@media only screen and (max-width: 767px) {
  .navbar-brand img {
    max-width: 120px;
  }
}

@media only screen and (max-width: 991px) {
  .header-wrap.style1 {
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  }
  .header-wrap .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header-wrap .navbar .navbar-brand img {
    max-width: 130px;
  }
  .header-wrap .navbar .navbar-collapse {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }
  .header-wrap .navbar .navbar-nav {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .header-wrap .navbar .navbar-nav .nav-item {
    position: relative;
  }
  .header-wrap .navbar .navbar-nav .nav-item .menu-expand {
    position: absolute;
    right: 0;
    top: 14px;
    cursor: pointer;
  }
  .header-wrap .navbar .navbar-nav .nav-item .menu-expand i {
    color: #000;
    font-size: 18px;
    line-height: 1;
    position: relative;
    top: 7px;
  }
  .header-wrap .navbar .navbar-nav .nav-item a {
    border-bottom: 1px solid #F3F4F6;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .header-wrap .navbar .navbar-nav .nav-item a i {
    display: none;
  }
  .header-wrap .navbar .navbar-nav .nav-item a.active {
    color: #0ABAB5;
  }
  .header-wrap .navbar .navbar-nav .nav-item .btn {
    margin-top: 15px;
    display: block;
    width: 100%;
  }
  .header-wrap .navbar .navbar-nav .nav-item .btn:after {
    display: none;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu {
    width: 100%;
    background-color: #fff;
    border: none;
    padding: 0;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 10px;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #0ABAB5;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
    margin-left: 20px;
  }
  .header-wrap .navbar .navbar-nav .nav-item .btn.style1 {
    margin-top: 15px;
    color: #fff;
  }
.mobile-bar-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    position: relative;
    top: 4px;
    right: 10px;
}
  .menu-close {
    position: absolute;
    top: 13px;
    right: 16px;
  }
  .menu-close i {
    color: #000;
    font-size: 25px;
  }
  .mobile-menu {
    text-align: right;
  }
  .mobile-menu a {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    background-color: #0ABAB5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mobile-menu a i {
    font-size: 22px;
    color: #fff;
    margin: 0 auto;
  }
  .mobile-menu {
    display: inline-block;
    position: relative;
    top: -1px;
  }
  .mobile-top-bar {
    display: inline-block;
    margin-right: 6px;
    cursor: pointer;
    position: relative;
    top: 0px;
  }
  .mobile-top-bar span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: #000;
    border-radius: 20px;
    margin: 5.9px 0;
  }
  .mobile-top-bar span:nth-child(1) {
    margin-top: 0;
  }
  .mobile-top-bar span:nth-child(2) {
    width: 12px;
    margin-left: 8px;
  }
  .main-menu-wrap {
    width: 100%;
    position: fixed;
    background: #fff;
    z-index: 999;
    top: 0;
    height: 100vh;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    left: -100%;
    padding: 55px 20px 30px;
    overflow-y: auto;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .main-menu-wrap.open {
    left: 0;
  }
  .main-menu > li.has-children.menu-open > span.menu-expand {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .main-menu > li.has-children.menu-open > span.menu-expand i {
    color: #000;
  }
  .main-menu > li > ul > li.has-children.menu-open > span.menu-expand {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .main-menu li {
    position: relative;
  }
  .main-menu li:last-child {
    margin: 0;
  }
  .main-menu li > span.menu-expand {
    position: absolute;
    right: 0;
    top: 13px;
    cursor: pointer;
  }
  .main-menu li > span.menu-expand i {
    color: #000;
    font-size: 18px;
  }
  .main-menu li > span.menu-expand:hover {
    color: #000;
  }
  .main-menu li a.active {
    color: #0ABAB5;
  }
  .main-menu li > a {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .main-menu li > a:hover {
    color: #000;
  }
  .main-menu li > ul.sub-menu {
    padding-left: 20px;
  }
  .header-wrap .header-bottom .main-menu-wrap #menu > ul > li > a::after {
    display: none;
  }
  .main-menu li a.active {
    font-weight: 600;
    color: #000;
    border-color: rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (min-width: 992px) {
  .menu-expand i {
    display: none;
  }
  .header-wrap .navbar {
    padding: 0;
  }
  .header-wrap .navbar .navbar-brand {
    padding: 0 20px 0 0;
  }
  .header-wrap .navbar ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
  }
  .header-wrap .navbar {
    padding: 0;
  }
  .header-wrap .navbar .navbar-brand {
    padding: 0 20px 0 0;
  }
  .header-wrap .navbar ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
  }
  .header-wrap .navbar .navbar-nav {
    margin-right: 30px;
  }
  .header-wrap .navbar .navbar-nav > .nav-item > a:after {
    position: absolute;
    bottom: 30px;
    left: 0;
    content: "";
    width: 0;
    height: 2px;
    background-color: #0ABAB5;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .header-wrap .navbar .navbar-nav .nav-item {
    position: relative;
    margin: 0 5px;
  }
  .header-wrap .navbar .navbar-nav .nav-item.first-child {
    margin-left: 0;
  }
  .header-wrap .navbar .navbar-nav .nav-item a {
    color: #000;
    font-weight: 600;
    padding: 30px 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: relative;
  }
  .header-wrap .navbar .navbar-nav .nav-item a:hover, .header-wrap .navbar .navbar-nav .nav-item a:focus, .header-wrap .navbar .navbar-nav .nav-item a.active {
    color: #0ABAB5;
  }
  .header-wrap .navbar .navbar-nav .nav-item a:hover:after, .header-wrap .navbar .navbar-nav .nav-item a:focus:after, .header-wrap .navbar .navbar-nav .nav-item a.active:after {
    visibility: visible;
    opacity: 1;
    width: 100%;
  }
  .header-wrap .navbar .navbar-nav .nav-item a i {
    font-size: 16px;
    line-height: 1;
    position: relative;
    top: 3px;
    display: inline-block;
    margin-left: -2px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .header-wrap .navbar .navbar-nav .nav-item a:hover i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu {
    display: block !important;
    border: none;
    top: 95px;
    left: -23px;
    z-index: 99;
    opacity: 0;
    width: 265px;
    padding: 10px 0;
    display: block;
    border-radius: 0;
    border-left: 2px solid #0ABAB5;
    border-radius: 0 0 5px 5px;
    position: absolute;
    visibility: hidden;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li {
    margin: 0;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li:last-child {
    border-bottom: none;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a {
    padding: 9px 20px;
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a::before {
    position: absolute;
    top: 18px;
    left: 20px;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #0ABAB5;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active, .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover {
    color: #0ABAB5;
    padding-left: 32px;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active:before, .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover:before {
    visibility: visible;
    opacity: 1;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    width: 100%;
    right: auto;
    left: 0;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a i {
    margin: 0;
    position: absolute;
    top: 55%;
    font-size: 16px;
    line-height: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    top: 0;
    opacity: 0;
    left: -250px;
    right: auto;
    visibility: hidden;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
  .header-wrap .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    top: 85px;
    opacity: 1;
    visibility: visible;
  }
  .header-wrap .navbar .others-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .header-wrap .header-bottom .navbar .navbar-nav .nav-item .dropdown-menu {
    height: 100vh;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-wrap .navbar .navbar-brand img {
    max-width: 130px;
  }
  .header-wrap .navbar .navbar-nav .nav-item {
    margin: 0 7px;
  }
  .header-wrap .navbar .navbar-nav .nav-item a {
    font-size: 15px;
  }
  .header-wrap .navbar .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
  }
  .header-wrap .header-btn button.btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  .btn{
      padding: 10px 15px 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-wrap .navbar .navbar-nav .nav-item {
    margin: 0 7px;
  }
  .header-wrap .header-btn button.btn {
    padding: 12px 25px;
  }
}

@media only screen and (min-width: 1400px) {
  .header-wrap .navbar .navbar-nav .nav-item {
    margin: 0 15px;
  }
  .header-wrap .navbar .navbar-nav .nav-item a {
    font-size: 16px;
  }
}


@media only screen and (min-width: 1600px) {
  .header-wrap .container {
    max-width: 1520px;
  }
}

/*---------------------------------
       Breadcrumb CSS 
-----------------------------------*/
.content-wrapper {
  margin-top: 85px;
}

.br-1 {
  background-image: url(../images/breadcrumb-1.jpg);
}

.br-2 {
  background-image: url(../images/breadcrumb-2.jpg);
}

.br-3 {
  background-image: url(../images/breadcrumb-3.jpg);
}

.br-4 {
  background-image: url(../images/breadcrumb-4.jpg);
}

.breadcrumb-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 0;
  overflow: hidden;
  z-index: 1;
}

.breadcrumb-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: -1;
}

.breadcrumb-title {
  position: relative;
  z-index: 1;
  width: 100%;
}

.breadcrumb-title h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 15px;
  color: #fff;
}

.breadcrumb-title .breadcrumb-menu li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  color: #fff;
  font-weight: 500;
}

.breadcrumb-title .breadcrumb-menu li:after {
  position: absolute;
  top: 1px;
  right: -13px;
  content: "/";
  font-size: 16px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumb-title .breadcrumb-menu li:first-child {
  margin-left: 0;
}

.breadcrumb-title .breadcrumb-menu li:first-child a {
  padding-left: 0;
}

.breadcrumb-title .breadcrumb-menu li:last-child {
  margin-right: 0;
  color: #0ABAB5;
}

.breadcrumb-title .breadcrumb-menu li:last-child:after {
  display: none;
}

.breadcrumb-title .breadcrumb-menu li a {
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  padding: 0px 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  position: relative;
  color: #fff;
}

.breadcrumb-title .breadcrumb-menu li a:hover {
  color: #0ABAB5;
}

.breadcrumb-title .breadcrumb-menu li:last-child a:after {
  display: none;
}

.breadcrumb-img img {
  display: block;
  margin-left: auto;
}

.content-feature-list {
  margin: 0 0 28px 0;
}

.content-feature-list li {
  position: relative;
  padding-left: 25px;
  margin: 0 0 18px;
}

.content-feature-list li:last-child {
  margin-bottom: 0;
}

.content-feature-list li i {
  position: absolute;
  top: 3px;
  left: 0;
  color: #0ABAB5;
  font-size: 15px;
  line-height: 0.8;
}

/*---------------------------------------
        Sign In & 404 page CSS
 ----------------------------------------*/
.modal-backdrop {
  opacity: 0.8 !important;
}

/* .login-bg {
  background-image: url(../images/signup-bg.jpg);
  height: 660px;
} */
.login-bg.bg-f {
background-image: url(../images/signup-bg.jpg);
height: 660px;
}
#signIn .modal-content,
#signUp .modal-content {
  border-radius: 0;
}

#signIn .modal-content .modal-header,
#signUp .modal-content .modal-header {
  padding: 0;
}

#signIn .modal-content .modal-header .btn-close,
#signUp .modal-content .modal-header .btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  opacity: 1;
  border-radius: 0;
  z-index: 9;
}

#signIn .modal-content .modal-header .btn-close:focus,
#signUp .modal-content .modal-header .btn-close:focus {
  outline: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

#signIn .modal-content .modal-header .btn-close i,
#signUp .modal-content .modal-header .btn-close i {
  font-size: 22px;
  line-height: 1;
  display: inline-block;
  color: #000;
}

#signIn .modal-content .modal-body,
#signUp .modal-content .modal-body {
  padding: 0;
}

.login-content {
    padding: 0 45px 0 45px;
}

.login-content h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.login-content p {
  margin-bottom: 25px;
}

.login-content .user-form .form-group {
    margin-bottom: 10px;
}
.login-content .user-form input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 54px;
    padding: 10px 15px;
/*    background: #fff;*/
}
.login-content .user-form select {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 54px;
  padding: 10px 15px;
/*    background: #fff;*/
}
.login-content .user-form input:checked {
    background-color: #0ABAB5;
    border-color: #0ABAB5;
}
.login-content .user-form button {
  display: block;
  width: 100%;
  margin-top: 15px;
}

.login-content .user-form button.fb, .login-content .user-form button.linkedin, .login-content .user-form button.twitter {
  margin: 0;
}

.login-content .social-profile {
  margin: 20px 0 20px;
}

.error-wrap {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.error-content {
  text-align: center;
}

.error-content img {
  display: block;
  margin: 0 auto 20px;
  max-width: 600px;
}

.error-content p {
  margin: 0 auto 20px;
  max-width: 520px;
}

/*---------------------------
        Sidebar CSS
 ---------------------------*/
.sidebar .sidebar-widget {
  margin: 0 0 30px;
  position: relative;
  z-index: 1;
  padding: 25px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.09);
}

.sidebar .sidebar-widget h4 {
  margin: 0 0 25px;
  font-size: 24px;
  position: relative;
  line-height: 1;
  position: relative;
}

.sidebar .sidebar-widget:last-child {
  margin: 0;
}

/*-------------------------------
       Search Box Widget CSS
-------------------------------*/
.search-box {
  position: relative;
}

.search-box .form-group {
  position: relative;
}

.search-box .form-group input {
  width: 100%;
  height: 54px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 15px;
  line-height: 21px;
  padding: 10px 60px 10px 20px;
  border-radius: 5px;
}

.search-box .form-group input:focus {
  border-color: #888888;
}

.search-box .form-group button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 18px;
  height: 100%;
  border: none;
  border-radius: 0 5px 5px 0;
  text-align: center;
  background: #0ABAB5;
}

.search-box .form-group button i {
  color: #fff;
  top: 4px;
  left: 1px;
  font-size: 20px;
  position: relative;
}

/*-------------------------------
     Category Widget CSS
-------------------------------*/
.category-box ul li {
  position: relative;
}

.category-box ul li:first-child a {
  padding-top: 0;
}

.category-box ul li:first-child a i {
  top: 6px;
}

.category-box ul li:last-child a {
  border-bottom: none;
  padding-bottom: 0;
}

.category-box ul li a {
  font-weight: 600;
  padding: 8px 0 8px 20px;
  display: block;
  font-size: 16px;
  color: #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.category-box ul li a i {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 10px;
  line-height: 0.8;
  color: #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.category-box ul li a span {
  float: right;
  font-weight: 400;
  margin-left: 4px;
  font-size: 16px;
  color: #666666;
}

.category-box ul li a:hover {
  color: #0ABAB5;
  padding-left: 25px;
}

.category-box ul li a:hover i {
  color: #0ABAB5;
}

/*-------------------------------
    Popular post Widget CSS
-------------------------------*/
.popular-post-slider.owl-carousel .owl-dots {
  text-align: center;
}

.popular-post-slider.owl-carousel .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  background-color: #fff;
  position: relative;
  border: none;
}

.popular-post-slider.owl-carousel .owl-dots .owl-dot span:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0ABAB5;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.popular-post-slider.owl-carousel .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.popular-post-slider.owl-carousel .owl-dots .owl-dot.active span:after {
  visibility: visible;
  opacity: 1;
}

/*-------------------------------
     Taglist Widget CSS
-------------------------------*/
.tag-list {
  margin-top: -12px;
}

.tag-list li {
  display: inline-block;
  margin: 12px 8px 0px 0px;
}

.tag-list li a {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 15px;
  color: #666666;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tag-list li a:hover {
  background-color: #0ABAB5;
  color: #fff;
  border-color: transparent;
}

.tag-list.style2 li a {
  padding: 0;
  color: #666666;
  background-color: transparent;
  border: none;
}

.tag-list.style2 li a:hover {
  background-color: transparent;
  color: #0ABAB5;
}

/*-------------------------------
    Recent Property Widget CSS
-------------------------------*/
.property-thumb-item {
  margin: 0 0 15px;
}

.property-thumb-item:last-child {
  margin-bottom: 0;
}

.property-thumb-item .property-thumb-img {
  width: 80px;
  border-radius: 5px;
}

.property-thumb-item .property-thumb-img img {
  border-radius: 5px;
}

.property-thumb-item .property-thumb-info {
  width: calc(100% - 95px);
  margin-left: 15px;
}

.property-thumb-item .property-thumb-info h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.property-thumb-item .property-thumb-info p {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #0ABAB5;
}

.property-thumb-item .property-thumb-info p span {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  color: #666666;
  margin-left: 2px;
}

/*-------------------------------
    Agent Box Widget CSS
-------------------------------*/
.agent-info-box .agent-name-wrap {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.agent-info-box .agent-name-wrap .agent-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.agent-info-box .agent-name-wrap .agent-img img {
    border-radius: 50%;
    height: 100%;
}

.agent-info-box .agent-name-wrap .agent-name {
  width: calc(100% - 85px);
  margin-left: 15px;
}

.agent-info-box .agent-name-wrap .agent-name h6 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 5px;
}

.agent-info-box .agent-name-wrap .agent-name span {
  font-size: 14px;
}

.agent-info-box .agent-address {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.agent-info-box .agent-address li {
  position: relative;
  margin-bottom: 12px;
}

.agent-info-box .agent-address li:last-child {
  margin-bottom: 0;
}

.agent-info-box .agent-address li i {
  color: #0ABAB5;
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.agent-info-box .agent-contact .form-group {
  margin-bottom: 25px;
}

.agent-info-box .agent-contact .form-group input, .agent-info-box .agent-contact .form-group textarea {
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  padding: 12px 15px;
  border-radius: 5px;
}

.agent-info-box .agent-contact .form-group input {
  height: 57px;
}

.agent-info-box .agent-contact .form-group textarea {
  height: 120px;
  resize: none;
}

.agent-info-box .agent-contact button {
  width: 100%;
  display: block;
}

/*-------------------------------
    Popular post Widget CSS
-------------------------------*/
.pp-post-item {
  margin: 0 0 20px;
}

.pp-post-item .pp-post-img {
  width: 85px;
  border-radius: 4px;
}

.pp-post-item .pp-post-img img {
  border-radius: 4px;
}

.pp-post-item .pp-post-info {
  width: calc(100% - 100px);
  margin-left: 15px;
  margin-top: 2px;
}

.pp-post-item .pp-post-info h6 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 0;
}

.pp-post-item .pp-post-info h6 a {
  color: #000;
}

.pp-post-item .pp-post-info span {
  font-size: 14px;
  line-height: 1;
  display: block;
  position: relative;
  padding-left: 22px;
  color: #888888;
  margin-bottom: 12px;
}

.pp-post-item .pp-post-info span i {
  position: absolute;
  top: -1px;
  left: 0;
  font-size: 13px;
  line-height: 0.8;
  color: #0ABAB5;
}

.pp-post-item:last-child {
  margin: 0;
}

.pp-post-item:hover .pp-post-info h6 a {
  color: #0ABAB5;
}

/*-------------------------------
    Hero  Section CSS
-------------------------------*/
.hero-bg-one {
  background-image: url(../images/hero-bg-1.jpg);
}

/*.hero-slide-1 {
  background-image: url(../images/hero-slider-1.jpg);
}*/

.hero-slide-2 {
  background-image: url(../images/hero-slider-2.jpg);
}

.hero-slide-3 {
  background-image: url(../images/hero-slider-3.jpg);
}

.hero-slide-4 {
  background-image: url(../images/hero-slider-4.jpg);
}

.hero-slide-5 {
  background-image: url(../images/hero-slider-5.jpg);
}

.hero-slide-6 {
  background-image: url(../images/hero-slider-6.jpg);
}

.hero-wrap {
  margin-top: 86px;
}

.hero-wrap .client-feedback ul {
  line-height: 1;
  padding-left: 8px;
}

.hero-wrap .client-feedback ul li {
  display: inline-block;
}

.hero-wrap .client-feedback ul li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: -8px;
  border: 2px solid #fff;
}

.hero-wrap .client-feedback ul li a img {
  border-radius: 50%;
}

.hero-wrap .client-feedback span {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 0;
  display: inline-block;
  color: #fff;
}

.hero-wrap .hero-content h1 {
  font-size: 52px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 18px;
}

.hero-wrap .hero-content p {
  margin-bottom: 30px;
}

.hero-wrap.style1 {
  padding: 0 0 100px;
  background: #2196f330;
}

.hero-wrap.style1 .hero-content {
  position: relative;
}

.hero-wrap.style1 .hero-content .hero-shape-one {
  position: absolute;
  top: -20px;
  right: 50px;
}

.hero-wrap.style1 .hero-content img {
  margin: 0 0 10px;
  display: block;
}

.hero-wrap.style1 .hero-content h1 {
  font-size: 56px;
}

.hero-wrap.style1 .hero-content form {
  position: relative;
  width: 90%;
}

.hero-wrap.style1 .hero-content form input {
  width: 100%;
  height: 64px;
  background-color: #f9f9f9;
  border: none;
  padding: 10px 115px 10px 47px;
  border-radius: 10px;
}

.hero-wrap.style1 .hero-content form i {
  position: absolute;
  top: 23px;
  left: 20px;
  color: #0ABAB5;
}

.hero-wrap.style1 .hero-content form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #0ABAB5;
  color: #fff;
  border: none;
  padding: 10px 25px;
  border-radius: 10px;
}
.hero-wrap.style1 .hero-content form button:after, .hero-wrap.style1 .hero-content form button:before {
    background: #111;
}
.hero-wrap.style1 .hero-img-wrap {
  position: relative;
}

.hero-wrap.style1 .hero-img-wrap .client-feedback {
  position: absolute;
  top: 25px;
  left: 110px;
}

.hero-wrap.style1 .hero-img-wrap .hero-bg-one {
    height: 650px;
    border-radius: 10px;
    width: calc(100% - 80px);
    margin-left: auto;
    margin-top: 50px;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: calc(100% - 100px);
  margin-left: auto;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav {
  position: absolute;
  bottom: 0;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next {
  position: relative;
  border: none;
  border-radius: 0;
  background-color: transparent;
  z-index: 1;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev i, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next i {
  margin: 0;
  font-size: 22px;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev:hover, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev:hover i, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next:hover i {
  color: #0ABAB5;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev.owl-prev, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next.owl-prev {
  text-align: left;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev.owl-prev:after, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next.owl-prev:after {
  left: 15px;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev.owl-next, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next.owl-next {
  text-align: right;
}

.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev.owl-next:after, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next.owl-next:after {
  right: 15px;
}

/*.hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-prev:after, .hero-wrap.style1 .hero-img-wrap .hero-property-slider .owl-nav button.owl-next:after {
  position: absolute;
  top: 13px;
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f9f9f9;
  z-index: -1;
}
*/
.hero-wrap.style2 {
  padding: 0;
  position: relative;
}

.hero-wrap.style2 .container-fluid {
  position: relative;
}

.hero-wrap.style2 .container-fluid .client-feedback {
  position: absolute;
  top: 25px;
  left: auto;
  right: 125px;
  z-index: 9;
  text-align: center;
}

.hero-wrap.style2 .container-fluid .client-feedback ul li a {
  width: 35px;
  height: 35px;
  border: 2px solid #fff;
}

.hero-wrap.style2 .container-fluid .client-feedback span {
  color: #0ABAB5;
  font-size: 14px;
}

.hero-wrap.style2 .hero-slide-item {
  position: relative;
  z-index: 1;
  /* padding: 160px 0 220px; */
  padding: 220px 0 220px;
  border-radius: 0px;
}

.hero-wrap.style2 .hero-slide-item:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    /* background: -webkit-gradient(linear, left top, right top, from(rgb(15 16 16 / 90%)), color-stop(66%, rgba(1, 34, 115, 0.02)), color-stop(67%, rgba(1, 34, 115, 0.04)));
    background: linear-gradient(90deg, rgb(10 10 10 / 90%) 0%, rgba(1, 34, 115, 0.02) 66%, rgba(1, 34, 115, 0.04) 67%); */
    z-index: -1;
    /* background-color: #00000040; */
    border-radius: 10px;
}

.hero-wrap.style2 .hero-slide-item .hero-content h1 {
  color: #fff;
  font-weight: 700;
}

.hero-wrap.style2 .hero-slide-item .hero-content h1 span {
  color: #0ABAB5;
  position: relative;
  z-index: 1;
}

.hero-wrap.style2 .hero-slide-item .hero-content h1 span img {
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 100%;
  z-index: -1;
}

.hero-wrap.style2 .hero-slide-item .hero-content p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 30px;
}

.hero-wrap.style2 .hero-slide-item .hero-content .btn.style2:before, .hero-wrap.style2 .hero-slide-item .hero-content .btn.style2:after {
  background-color: #fff;
}

.hero-wrap.style2 .hero-slide-item .hero-content .btn.style2:hover {
  color: #0ABAB5;
}

.hero-wrap.style3 {
  padding: 0;
  position: relative;
}

.hero-wrap.style3 .hero-slider-two .hero-slide-item {
  height: 840px;
  position: relative;
  z-index: 1;
}

.hero-wrap.style3 .hero-slider-two .hero-slide-item:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

.hero-wrap.style3 .hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hero-wrap.style3 .hero-content h1 {
  color: #fff;
}

.hero-wrap.style3 .hero-content p {
  color: rgba(255, 255, 255, 0.8);
  padding: 0 60px;
}

.hero-wrap.style3 .hero-content .client-feedback {
  position: relative;
  top: auto;
  left: auto;
  margin: 25px 0 0;
}

.hero-wrap.style3 .hero-content .client-feedback span {
  margin: 0 0 0 15px;
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap {
  width: calc(100% - 225px);
  margin-right: 22px;
  background-color: #fff;
  border-radius: 5px;
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group {
  width: 33.33%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group input, .hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group select {
  border: none;
  height: 57px;
  background-color: transparent;
  width: 100%;
  padding: 15px 15px 10px 15px;
  border-radius: 0;
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group input::-webkit-input-placeholder {
  opacity: 1;
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group input:-ms-input-placeholder {
  opacity: 1;
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group input::-ms-input-placeholder {
  opacity: 1;
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group input::placeholder {
  opacity: 1;
}

.hero-wrap.style3 .hero-content .property-search-form .form-group-wrap .form-group select {
  background-position: 95% 57%;
}

.hero-wrap.style3 .hero-content .property-search-form button {
  width: 200px;
  display: block;
  padding: 15px;
}

.hero-property-slider {
  margin: -10px -10px;
}

.hero-property-slider .property-card {
  margin: 10px;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev, .hero-slider-one.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  background-color: #f9f9f9;
  border: 2px solid #fff;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev i, .hero-slider-one.owl-carousel .owl-nav button.owl-next i {
  color: #0ABAB5;
  font-size: 30px;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev:hover, .hero-slider-one.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #0ABAB5;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev:hover i, .hero-slider-one.owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-prev {
  left: -40px;
}

.hero-slider-one.owl-carousel .owl-nav button.owl-next {
  right: -40px;
}

.hero-slider-two.owl-carousel .owl-nav button.owl-prev, .hero-slider-two.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  z-index: 10;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.hero-slider-two.owl-carousel .owl-nav button.owl-prev i, .hero-slider-two.owl-carousel .owl-nav button.owl-next i {
  color: #fff;
  font-size: 30px;
}

.hero-slider-two.owl-carousel .owl-nav button.owl-prev:hover, .hero-slider-two.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #0ABAB5;
}

.hero-slider-two.owl-carousel .owl-nav button.owl-prev:hover i, .hero-slider-two.owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

.hero-slider-two.owl-carousel .owl-nav button.owl-prev {
  left: 40px;
  z-index: 1;
}

.hero-slider-two.owl-carousel .owl-nav button.owl-next {
  right: 40px;
  z-index: 1;
}

.search-property {
    background-color: #fff;
    border-radius: 10px;
    padding: 0;
    z-index: 1;
    position: relative;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
    margin-top: 25px;
}

/*.search-property .property-search-form .form-group {
    width: calc(27% - -8px);
    margin-right: 0;
}
*/
.property-search-form .form-group {
    flex: 1;
}
.search-property .property-search-form .form-group:last-child {
  width: 17%;
  margin-right: 0;
}

.search-property .property-search-form .form-group:last-child button.btn {
    width: 100%;
    display: block;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.search-property .property-search-form .form-group input, .search-property .property-search-form .form-group select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 57px;
  background-color: transparent;
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
}

.search-property .property-search-form .form-group button {
  padding: 15px;
}

.search-property .property-search-form .form-group input::-webkit-input-placeholder {
  opacity: 1;
}

.search-property .property-search-form .form-group input:-ms-input-placeholder {
  opacity: 1;
}

.search-property .property-search-form .form-group input::-ms-input-placeholder {
  opacity: 1;
}

.search-property .property-search-form .form-group input::placeholder {
  opacity: 1;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content h1,
.hero-slider-two.owl-carousel .owl-item.active .hero-slide-item .hero-content h1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: .7s;
  animation-delay: .7s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content p,
.hero-slider-two.owl-carousel .owl-item.active .hero-slide-item .hero-content p {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
}

.hero-slider-one.owl-carousel .owl-item.active .hero-slide-item .hero-content .btn,
.hero-slider-two.owl-carousel .owl-item.active .hero-slide-item .hero-content .btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

/*-------------------------------
    About  Section CSS
-------------------------------*/
.about-bg-1 {
  background-image: url(../images/about-img-3.jpg);
}

.about-bg-2 {
  background-image: url(../images/about-img-4.jpg);
}

.about-bg-3 {
  background-image: url(../images/about-img-5.jpg);
}

.about-bg-4 {
  background-image: url(../images/about-img-6.jpg);
}

.about-wrap .about-content .content-title {
  margin-bottom: 25px;
}

.about-wrap .about-content .content-feature-list {
  margin-bottom: 35px;
}

.about-wrap .about-content .content-feature-list li {
  font-weight: 600;
  color: #000;
}

.about-wrap.style1 {
  position: relative;
}

.about-wrap.style1 .about-shape-three {
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.about-wrap.style1 .about-img-wrap {
  position: relative;
  z-index: 1;
  padding-bottom: 150px;
}

.about-wrap.style1 .about-img-wrap .about-shape-one {
  position: absolute;
  bottom: 0;
  left: 50px;
  z-index: 1;
}

.about-wrap.style1 .about-img-wrap .about-shape-two {
  position: absolute;
  top: 60px;
  right: 50px;
  z-index: -1;
}

.about-wrap.style1 .about-img-wrap .about-img-one {
  margin-right: auto;
  width: calc(100% - 120px);
  border-radius: 5px;
}

.about-wrap.style1 .about-img-wrap .about-img-two {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 5px;
}

.about-wrap.style2 .about-box {
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.about-wrap.style2 .about-box .about-shape-three {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.about-wrap.style2 .about-box .about-content {
  padding-right: 40px;
}

.about-wrap.style2 .about-box .about-img-wrap {
  position: relative;
  height: 640px;
}

.about-wrap.style2 .about-box .about-img-wrap:before {
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  -webkit-transform: trasnlateX(-50%);
  transform: trasnlateX(-50%);
  content: "";
  width: 20px;
  height: 100%;
  background-color: #fff;
}

.about-wrap.style3 .about-img-wrap {
  margin: 0;
}

.about-wrap.style3 .about-img-wrap .about-bg-2,
.about-wrap.style3 .about-img-wrap .about-bg-3,
.about-wrap.style3 .about-img-wrap .about-bg-4 {
  height: 500px;
  border-radius: 5px;
}

/*-------------------------------
    Advantage & Simple  Section CSS
-------------------------------*/
.adv-card {
  margin: 0 0 25px;
}

.adv-card .adv-img {
  position: relative;
  z-index: 1;
  margin-bottom: 35px;
}

.adv-card .adv-img:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: #2196f330;
    z-index: -1;
}

.adv-card .adv-img img {
  display: block;
  margin: 0 auto;
}

.adv-card .adv-info {
    text-align: center;
    padding: 0 30px;
    padding-top: 15px;
}

.adv-card .adv-info h3 {
  font-size: 22px;
  margin: 0 0 12px;
}

.adv-card .adv-info p {
  margin: 0;
}

.simple-card {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.simple-card .simple-card-info {
  padding: 25px;
}

.simple-card .simple-card-info h3 {
  font-size: 22px;
  margin: 0 0 15px;
}

.simple-card .simple-card-info p {
  margin-bottom: 12px;
}

.simple-card:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

/*--------------------------------------------
    Promo Video  & add-listing Section CSS
-----------------------------------------------*/
.add-listing-bg {
/*  background-image: url(../images/add-listing-bg.jpg);*/
  height: 100%;
}

.add-listing-wrap .listing-content {
  position: relative;
  z-index: 1;
  padding-left: 60px;
  background-color: #0ABAB5;
}

.add-listing-wrap .listing-content .add-listing-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.add-listing-wrap .listing-content .content-title {
  margin-bottom: 25px;
}

.add-listing-wrap .listing-content .content-title h2 {
    color: #fff;
    font-size: 35px;
}
.add-listing-wrap .listing-content .content-title p {
  color: rgba(255, 255, 255, 0.8);
}

.video-bg-1 {
  background-image: url(../images/promo-video-bg.jpg);
}

.video-bg-2 {
  background-image: url(../images/promo-video-bg-2.jpg);
}

.video-bg-3 {
  background-image: url(../images/promo-video-bg-3.jpg);
}

.video-bg-4 {
  background-image: url(../images/promo-video-bg-4.jpg);
}

.video-wrap .video-content {
    padding: 70px;
    position: relative;
    z-index: 1;
    margin-left: 30px;
}

.video-wrap .video-content:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.86);
  z-index: -1;
}

.video-wrap .video-content .content-title {
  margin-bottom: 25px;
}

.video-wrap.style2 {
  position: relative;
  z-index: 1;
}

.video-wrap.style2:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #0ABAB5;
  opacity: 0.74;
  z-index: -1;
}

.video-wrap.style2 .video-content {
  padding: 0 0 100px;
}

.video-wrap.style2 .video-content:after {
  display: none;
}

.video-wrap.style2 .video-content .content-title {
  text-align: center;
  margin: 35px 0 0;
}

.video-wrap.style2 .video-content .content-title span, .video-wrap.style2 .video-content .content-title h2 {
  color: #fff;
}

.video-wrap.style3 .video-bg {
  height: 500px;
  position: relative;
  z-index: 1;
}

.video-wrap.style3 .video-bg:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #0ABAB5;
  opacity: 0.6;
  z-index: -1;
}

.video-wrap.style3 .video-bg .play-now {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-slider .video-item {
  border-radius: 8px;
}

.video-slider .video-item img {
  border-radius: 8px;
}

#big .video-bg {
  border-radius: 5px;
}

#big .video-bg:after {
  border-radius: 5px;
}

#thumbs {
  margin: 25px 0 0;
}

#thumbs .video-item {
  border-radius: 5px;
  cursor: pointer;
}

#thumbs .video-item img {
  border-radius: 5px;
}

#thumbs.owl-carousel .owl-nav button.owl-prev, #thumbs.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  width: auto;
  border-radius: 0;
}

#thumbs.owl-carousel .owl-nav button.owl-prev i, #thumbs.owl-carousel .owl-nav button.owl-next i {
  font-size: 34px;
  color: #0ABAB5;
}

#thumbs.owl-carousel .owl-nav button.owl-prev:hover, #thumbs.owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

#thumbs.owl-carousel .owl-nav button.owl-prev {
  left: -35px;
}

#thumbs.owl-carousel .owl-nav button.owl-next {
  right: -35px;
}

/*-------------------------------
    Popular City  Section CSS
-------------------------------*/
.city-wrap {
  position: relative;
  z-index: 1;
}

.city-wrap .city-shape-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.city-wrap .city-shape-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  z-index: -1;
}

.city-card {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 0 0 25px;
  border-radius: 10px;
}

/*.city-card:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.9)), color-stop(60%, rgba(0, 0, 0, 0.15)));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.15) 60%);
}
*/


.city-card img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.city-card h3 {
  font-size: 22px;
}

.city-card .city-info {
  position: absolute;
  bottom: 20px;
  left: 25px;
  z-index: 1;
}

.city-card .city-info h3 {
  font-size: 22px;
  margin: 0 0 8px;
}

.city-card .city-info h3 a {
  color: #fff;
  position: relative;
  padding-bottom: 5px;
}

.city-card .city-info h3 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 2px;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.city-card .city-info p {
  color: #fff;
  margin: 0;
}

.city-card.style1:hover .city-info h3 a:after {
  width: 100%;
  visibility: visible;
  opacity: 1;
}

.city-card.style2 h3 a {
  position: relative;
  padding-bottom: 5px;
}

.city-card.style2 h3 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 2px;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.city-card.style2:hover h3 a:after {
  width: 100%;
  visibility: visible;
  opacity: 1;
}

.city-card.style2:after, .city-card.style3:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(1, 34, 115, 0.9)), color-stop(66%, rgba(1, 34, 115, 0.02)), color-stop(67%, rgba(1, 34, 115, 0.04)));
  background: linear-gradient(0deg, rgba(1, 34, 115, 0.9) 0%, rgba(1, 34, 115, 0.02) 66%, rgba(1, 34, 115, 0.04) 67%);
}

.city-card.style2 p, .city-card.style3 p {
  position: absolute;
  top: 25px;
  right: 25px;
  display: inline-block;
  padding: 5px 12px 4px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #0ABAB5;
  color: #fff;
}

.city-card.style2 h3, .city-card.style3 h3 {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.city-card.style2 h3 a, .city-card.style3 h3 a {
  color: #fff;
}

.city-card.style3 h3 {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.city-card.style3:hover h3 {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.city-card:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);

}

.city-slider-one.owl-carousel .owl-dots {
  margin: 20px 0 0;
  text-align: center;
}

/*-------------------------------
    Property  Section CSS
-------------------------------*/
.agent-tablist,
.property-tablist {
  border: none;
  margin-bottom: 30px;
}

.agent-tablist .nav-item,
.property-tablist .nav-item {
  margin-right: 25px;
}

.agent-tablist .nav-item:last-child,
.property-tablist .nav-item:last-child {
  margin-right: 0;
}

.agent-tablist .nav-item .nav-link,
.property-tablist .nav-item .nav-link {
  background-color: transparent;
  border: none;
  position: relative;
  color: #0ABAB5;
  font-weight: 600;
  display: inline-block;
  padding: 0 0 10px;
}

.agent-tablist .nav-item .nav-link:after,
.property-tablist .nav-item .nav-link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 2px;
  background-color: #0ABAB5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.agent-tablist .nav-item .nav-link.active,
.property-tablist .nav-item .nav-link.active {
  color: #0ABAB5;
}

.agent-tablist .nav-item .nav-link.active:after,
.property-tablist .nav-item .nav-link.active:after {
  width: 100%;
}

.property-card {
  margin-bottom: 25px;
}

.property-card .property-img {
  overflow: hidden;
  position: relative;
}

.property-card .property-img img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.property-card .property-img-slider {
  overflow: hidden;
}

.property-card .property-img-slider img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.property-card .property-img-slider.owl-carousel .owl-nav button.owl-prev, .property-card .property-img-slider.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 38px;
  height: 38px;
  border: none;
  background-color: rgba(0, 33, 114, 0.45);
}

.property-card .property-img-slider.owl-carousel .owl-nav button.owl-prev i, .property-card .property-img-slider.owl-carousel .owl-nav button.owl-next i {
  color: #fff;
}

.property-card .property-img-slider.owl-carousel .owl-nav button.owl-prev:hover, .property-card .property-img-slider.owl-carousel .owl-nav button.owl-next:hover {
  background: #0ABAB5;
}

.property-card .property-img-slider.owl-carousel .owl-nav button.owl-prev {
  left: 10px;
}

.property-card .property-img-slider.owl-carousel .owl-nav button.owl-next {
  right: 10px;
}

.property-card .property-status {
  display: inline-block;
  padding: 5px 12px 4px;
  border-radius: 5px;
}

.property-card .property-price {
  font-weight: 700;
  margin: 0;
}

.property-card .property-price span {
  color: #666666;
  margin-left: 3px;
  font-size: 14px;
  font-weight: 400;
}

.property-card .property-info {
  padding: 25px 25px;
}

.property-card .property-info .property-status-wrap {
  margin-bottom: 20px;
}

.property-card .property-info .property-status-wrap .property-status {
  background-color: #0ABAB5;
  color: #fff;
}

.property-card .property-info .property-status-wrap .property-price {
  color: #0ABAB5;
  margin: 0;
}

.property-card .property-info h3 {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 5px;
}

.property-card .property-info h3 a:hover {
  color: #0ABAB5;
}

.property-card .property-info p {
    margin: 0 0 20px;
    text-transform: capitalize;
}

.property-card .property-info p i {
  position: relative;
  top: 2px;
  color: #0ABAB5;
  font-size: 15px;
  margin-right: 5px;
}

.property-card .property-info .property-metainfo {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.property-card .property-info .property-metainfo li {
  width: 50%;
  padding: 10px 5px;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  text-align: center;
  font-size: 15px;
}

.property-card .property-info .property-metainfo li:last-child {
  width: 36.33%;
  border-right: none;
}

.property-card .property-info .property-metainfo li i {
  color: #0ABAB5;
  position: relative;
  top: 1px;
  font-size: 14px;
  margin-right: 5px;
}

.property-card:hover .property-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.property-card.style1 {
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
}

.property-card.style1 .property-img {
  border-radius: 5px 5px 0 0;
}

.property-card.style1 .property-img img {
  border-radius: 5px 5px 0 0;
}

.property-card.style1 .property-info {
  padding: 22px 25px 22px;
}

.property-card.style1 .property-info h3 {
  margin: 0 0 12px;
}

.property-card.style1 .property-info p {
  margin: 0 0 15px;
}

.property-card.style1 .property-info .ratings {
  font-size: 14px;
  font-weight: 600;
  color: #0ABAB5;
}

.property-card.style1 .property-info .ratings i {
  color: #FFC422;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.property-card.style2 .property-img-slider {
  overflow: hidden;
}

.property-card.style2 .property-img-slider img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.property-card.style2:hover .property-img-slider img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.property-card.style2, .property-card.style5 {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
}

.property-card.style5 .property-info .property-metainfo {
  background-color: #f9f9f9;
  border: none;
}

.property-card.style3, .property-card.style6 {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  overflow: hidden;
}

.property-card.style3 .property-img, .property-card.style6 .property-img {
  position: relative;
}

.property-card.style3 .property-img .property-status, .property-card.style6 .property-img .property-status {
  position: absolute;
  top: 25px;
  right: 25px;
  display: inline-block;
  background-color: #0ABAB5;
  font-size: 14px;
  padding: 4px 15px;
  border-radius: 5px;
  color: #fff;
}

.property-card.style3 .property-img .property-condo, .property-card.style6 .property-img .property-condo {
  position: absolute;
  top: 15px;
  left: -70px;
  display: inline-block;
  background-color: #0ABAB5;
  font-size: 14px;
  font-weight: 600;
  width: 200px;
  padding: 4px 15px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.property-card.style3 .property-info .property-status-wrap, .property-card.style6 .property-info .property-status-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.property-card.style3 .property-info .property-status-wrap .property-state i, .property-card.style6 .property-info .property-status-wrap .property-state i {
  color: #0ABAB5;
  position: relative;
  top: 1px;
  margin-right: 6px;
}

.property-card.style3 .property-info .property-metainfo, .property-card.style6 .property-info .property-metainfo {
  border: none;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}

.property-card.style4 {
  border-radius: 10px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
}

.property-card.style4 .property-img {
  border-radius: 10px 10px 0 0;
}

.property-card.style4 .property-img img {
  border-radius: 10px 10px 0 0;
  width:100%;
}

.property-card.style4 .property-img .property-status {
  position: absolute;
  top: 25px;
  left: 25px;
  background-color: #0ABAB5;
  color: #fff;
  text-transform: capitalize;
}

.property-card.style4 .property-info {
  padding: 22px 20px 20px;
}

.property-card.style4 .property-info .property-price {
  margin: 18px 0 0;
  color: #0ABAB5;
}

.property-card.style5 {
  border-radius: 5px 5px 20px 20px;
}

.property-card.style5 .property-info {
  border-radius: 20px 20px 8px 8px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  margin-top: -30px;
  position: relative;
  z-index: 1;
}

.property-card.style6 {
  border-radius: 5px 5px 20px 20px;
}

.property-card.style6 .property-info {
  border-radius: 20px 20px 8px 8px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  margin-top: -30px;
  position: relative;
  z-index: 1;
}

.property-slider-one {
  margin: -10px 0;
}

.property-slider-one .property-card {
  margin: 10px 0;
}

.property-slider-one.owl-carousel .owl-dots {
  margin: 20px 0 10px;
  text-align: center;
}

.property-slider-two {
  margin: -10px 0;
}

.property-slider-two .property-card {
  margin: 10px 0;
}

.property-slider-two.owl-carousel .owl-dots .owl-dot span:after {
  display: none;
}

.filter-item-num select,
.filter-item-cat select {
  width: 100%;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  color: #393939;
  background-color: #f9f9f9;
  border: none;
  font-size: 14px;
  height: 54px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../images/down-arrow.png);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 90% 60%;
}

.city-slider-two.owl-carousel .owl-dots,
.testimonial-slider-three.owl-carousel .owl-dots,
.property-slider-two.owl-carousel .owl-dots,
.property-slider-three.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.city-slider-two.owl-carousel .owl-dots .owl-dot span,
.testimonial-slider-three.owl-carousel .owl-dots .owl-dot span,
.property-slider-two.owl-carousel .owl-dots .owl-dot span,
.property-slider-three.owl-carousel .owl-dots .owl-dot span {
  width: 24px;
  height: 6px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}

.city-slider-two.owl-carousel .owl-dots .owl-dot span:after,
.testimonial-slider-three.owl-carousel .owl-dots .owl-dot span:after,
.property-slider-two.owl-carousel .owl-dots .owl-dot span:after,
.property-slider-three.owl-carousel .owl-dots .owl-dot span:after {
  display: none;
}

.city-slider-two.owl-carousel .owl-dots .owl-dot.active span,
.testimonial-slider-three.owl-carousel .owl-dots .owl-dot.active span,
.property-slider-two.owl-carousel .owl-dots .owl-dot.active span,
.property-slider-three.owl-carousel .owl-dots .owl-dot.active span {
  background-color: #0ABAB5;
}

/*-------------------------------
    How It Works  Section CSS
-------------------------------*/
.hw-card {
  margin-bottom: 25px;
}

.hw-card .hw-img {
  position: relative;
  z-index: 1;
  margin: 0 0 35px;
}

.hw-card .hw-img img {
  display: block;
  margin: 0 auto;
}

.hw-card .hw-img .hw-shape-one {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.hw-card .hw-img span {
  z-index: 1;
  font-size: 60px;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 70px;
  color: rgba(0, 0, 0, 0.14);
  font-family: "Poppins", sans-serif;
}

.hw-card .hw-info {
  padding: 0 20px;
  text-align: center;
}

.hw-card .hw-info h3 {
  font-size: 22px;
  margin: 0 0 10px;
}

.hw-card .hw-info p {
  margin: 0;
}

.hw-card.style2 .hw-img .hw-shape-one {
  max-width: 300px;
}

/*-------------------------------
    Partner  Section CSS
-------------------------------*/
.partner-item {
  padding: 0 20px;
}

.partner-item img {
  display: block;
  margin: 0 auto;
  max-width: 150px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.partner-item:hover img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}

/*-------------------------------
    Testimonial  Section CSS
-------------------------------*/
.testimonial-wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.testimonial-wrap .testimonial-shape-one {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.testimonial-card .client-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 35px;
}

.testimonial-card .client-img img {
  border-radius: 50%;
}

.testimonial-card .quote-wrap {
  padding: 25px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  position: relative;
  background-color: #fff;
}

.testimonial-card .quote-wrap:before {
  position: absolute;
  top: -25px;
  left: 50%;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-bottom: 25px solid #fff;
  border-right: 20px solid transparent;
  -webkit-box-shadow: -11px 14px 46px -2px rgba(0, 0, 0, 0.1);
          box-shadow: -11px 14px 46px -2px rgba(0, 0, 0, 0.1);
}

.testimonial-card .quote-wrap .client-quote {
  margin: 0 0 15px;
}

.testimonial-card .quote-wrap .client-info-wrap {
  background-color: #fff;
  position: relative;
}

.testimonial-card .quote-wrap .client-info-wrap .client-info h5 {
  font-size: 20px;
  margin: 0 0 6px;
}

.testimonial-card .quote-wrap .client-info-wrap .client-info span {
  color: #666666;
}

.testimonial-card .quote-wrap .client-info-wrap .ratings {
  font-weight: 600;
  color: #0ABAB5;
  position: relative;
  top: 2px;
}

.testimonial-card .quote-wrap .client-info-wrap .ratings i {
  color: #FFC422;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.testimonial-card.style1 {
  padding: 0;
}

.testimonial-card.style2 h6 {
  line-height: 1.5;
}

.testimonial-card.style2 .client-info-wrap .client-img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin: 0;
}

.testimonial-card.style2 .client-info-wrap .client-img img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.testimonial-card.style2 .client-info-wrap .client-info {
  width: calc(100% - 130px);
  margin-left: 15px;
}

.testimonial-card.style2 .client-info-wrap .client-info h5 {
  font-size: 20px;
  margin: 0 0 5px;
}

.testimonial-card.style2 .client-info-wrap .client-info span {
  font-size: 14px;
}

.testimonial-card.style2 .client-info-wrap .ratings {
  width: 53px;
  text-align: right;
}

.testimonial-card.style2 .client-info-wrap .ratings i {
  color: #FFC422;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.testimonial-card.style2, .testimonial-card.style3 {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  padding: 25px;
  background-color: #fff;
}

.testimonial-card.style2 h6, .testimonial-card.style3 h6 {
  font-size: 18px;
  margin: 0 0 15px;
}

.testimonial-card.style2 .client-quote, .testimonial-card.style3 .client-quote {
  margin: 0 0 15px;
}

.testimonial-card.style3 .client-info-wrap .client-img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin: 0;
}

.testimonial-card.style3 .client-info-wrap .client-img img {
  border-radius: 50%;
}

.testimonial-card.style3 .client-info-wrap .client-info {
  width: calc(100% - 77px);
  margin-left: 15px;
}

.testimonial-card.style3 .client-info-wrap .client-info h5 {
  font-size: 20px;
  margin: 0 0 5px;
}

.testimonial-card.style3 .client-info-wrap .client-info span {
  font-size: 14px;
}

.testimonial-slider-one {
  margin: -10px 0;
}

.testimonial-slider-one .testimonial-card {
  margin: 10px 0;
}

.testimonial-slider-one.owl-carousel .owl-dots {
  margin: 20px 0 10px;
  text-align: center;
}

.testimonial-slider-two {
  margin: -10px;
}

.testimonial-slider-two .testimonial-card {
  margin: 10px;
}

.testimonial-slider-two.owl-carousel .owl-nav {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 25px 0px 0px -100px;
}

.testimonial-slider-two.owl-carousel .owl-nav button.owl-prev, .testimonial-slider-two.owl-carousel .owl-nav button.owl-next {
  width: 50px;
  height: 50px;
  margin: 0 7px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
}

.testimonial-slider-two.owl-carousel .owl-nav button.owl-prev:hover, .testimonial-slider-two.owl-carousel .owl-nav button.owl-next:hover {
  background-color: #0ABAB5;
}

.testimonial-slider-two.owl-carousel .owl-nav button.owl-prev:hover i, .testimonial-slider-two.owl-carousel .owl-nav button.owl-next:hover i {
  color: #fff;
}

.testimonial-slider-two.owl-carousel .owl-nav button.owl-prev i, .testimonial-slider-two.owl-carousel .owl-nav button.owl-next i {
  color: #0ABAB5;
  font-size: 22px;
}

/*-------------------------------
    Counter  Section CSS
-------------------------------*/
.counter-area {
  position: relative;
  z-index: 1;
}

.counter-area.style4:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 50%;
  z-index: -1;
  background-color: #f9f9f9;
}

.counter-card-wrap .counter-card {
  width: 25%;
  position: relative;
  margin-bottom: 25px;
}

.counter-card-wrap .counter-card .counter-icon {
  position: relative;
  top: 5px;
  display: inline-block;
  text-align: center;
  width: 55px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.counter-card-wrap .counter-card .counter-icon i {
  display: inline-block;
  font-size: 55px;
  line-height: 0.8;
  color: #0ABAB5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.counter-card-wrap .counter-card .counter-text {
  width: calc(100% - 95px);
  margin-left: 20px;
}

.counter-card-wrap .counter-card .counter-text h2 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1;
  margin: 0 0 5px;
  color: #0ABAB5;
}

.counter-card-wrap .counter-card .counter-text h2 .target {
  font-size: 54px;
  line-height: 0.8;
  position: relative;
  top: 4px;
  left: 0;
}

.counter-card-wrap .counter-card .counter-text p {
  margin-bottom: 0;
  line-height: 1;
}

.counter-card-wrap.style1 .counter-card:after, .counter-card-wrap.style4 .counter-card:after {
  position: absolute;
  top: 50%;
  right: 45px;
  content: "";
  width: 1px;
  height: 65px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.counter-card-wrap.style1 .counter-card:last-child:after, .counter-card-wrap.style4 .counter-card:last-child:after {
  display: none;
}

.counter-card-wrap.style2 {
  background-color: #fff;
  padding: 60px 60px 35px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-top: -100px;
  position: relative;
  z-index: 1;
}

.counter-card-wrap.style2 .counter-card {
  position: relative;
  z-index: 1;
  text-align: center;
}

.counter-card-wrap.style2 .counter-card .counter-card-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.counter-card-wrap.style2 .counter-card .counter-text {
  margin: 0;
  text-align: center;
  width: 100%;
}

.counter-card-wrap.style3 .counter-card:after {
  position: absolute;
  top: 50%;
  right: 0;
  content: "";
  width: 1px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.counter-card-wrap.style3 .counter-card:last-child:after {
  display: none;
}

.counter-card-wrap.style3 .counter-card .counter-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
}

.counter-card-wrap.style3 .counter-card .counter-icon i {
  font-size: 125px;
  line-height: 0.8;
  opacity: 0.06;
}

.counter-card-wrap.style3 .counter-card .counter-text {
  margin: 0;
  width: 100%;
  text-align: center;
}

.counter-card-wrap.style4 {
  border-radius: 8px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  padding: 70px 70px 45px;
  display: none;
}

.counter-card-wrap.style4 .counter-card:after {
  display: none;
}

/*----------------------------
    FAQ  CSS
------------------------------*/
.faq-wrap .faq-content .content-title {
  margin-bottom: 25px;
}

.faq-wrap.style1 .accordion-item {
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
}

.faq-wrap.style1 .accordion-item .accordion-button {
  background-color: #fff;
  border: none;
}

.faq-wrap.style2 .accordion-item {
  background-color: #fff;
}

.faq-wrap.style2 .accordion-item .accordion-button {
  background-color: #fff;
  border: none;
}

.faq-wrap.style3 .accordion-item {
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
}

.faq-wrap.style3 .accordion-item .accordion-button {
  background-color: #fff;
  border: none;
}

.faq-wrap.style4 .accordion-item {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.faq-wrap.style4 .accordion-item .accordion-button {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.accordion-item {
  border: none;
  margin: 0 0 15px;
  background: transparent;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-item .accordion-header {
  margin-bottom: 0;
}

.accordion-item .accordion-header .accordion-button {
  border-radius: 5px 5px 0 0;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 5px;
  color: #000;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 65px 20px 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: relative;
}

.accordion-item .accordion-header .accordion-button:after {
  display: none;
}

.accordion-item .accordion-header .accordion-button:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}

.accordion-item .accordion-header .accordion-button.collapsed {
  border: 1px solid rgba(255, 255, 255, 0.98);
}

.accordion-item .accordion-header .accordion-button.collapsed span i {
  color: #0ABAB5;
}

.accordion-item .accordion-header .accordion-button:not(collapsed) {
  border: 1px solid rgba(255, 255, 255, 0.98);
}

.accordion-item .accordion-header .accordion-button:not(collapsed) span i {
  color: #0ABAB5;
}

.accordion-item .accordion-header .accordion-button span {
  display: inline-block;
  min-width: 50px;
  text-align: center;
  position: absolute;
  top: 32px;
  right: 4px;
  border-radius: 5px;
}

.accordion-item .accordion-header .accordion-button span i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 15px;
  line-height: 0.8;
  color: #0ABAB5;
}

.accordion-item .accordion-header .accordion-button span i.minus {
  visibility: hidden;
  opacity: 0;
}

.accordion-item .accordion-header .accordion-button span i.minus {
  visibility: hidden;
  opacity: 0;
}

.accordion-item .accordion-body {
  border: none;
  padding: 20px 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-item .accordion-body p {
  margin: 0;
}

.accordion-collapse {
  border: none;
}

.accordion-item .accordion-collapse .accordion-body {
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-top: none;
  padding: 0 20px 20px;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: transparent !important;
  border-bottom: none;
}

.accordion-button:not(.collapsed) span i.plus {
  visibility: hidden;
  opacity: 0;
}

.accordion-button:not(.collapsed) span i.minus {
  visibility: visible !important;
  opacity: 1 !important;
}

.newsletter-wrap {
  position: relative;
  z-index: 1;
}

.newsletter-wrap .newsletter-shape-one {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.newsletter-form {
  position: relative;
  z-index: 1;
}

.newsletter-form .form-group {
  width: calc(39% - 15px);
  margin-right: 25px;
}

.newsletter-form .form-group input {
  height: 54px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  width: 100%;
  border: none;
  background-color: #fff;
  padding: 10px 20px;
}

.newsletter-form .form-group:last-child {
  margin-right: 0;
  width: 20%;
}

.newsletter-form .form-group:last-child button.btn {
  width: 100%;
  display: block;
}

/*----------------------------
    Filter Widget  CSS
------------------------------*/
.price-range .ui-slider-handle.ui-corner-all.ui-state-default {
  background: #0ABAB5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: none;
  -webkit-box-shadow: 0px 5px 13px rgba(167, 167, 167, 0.7);
          box-shadow: 0px 5px 13px rgba(167, 167, 167, 0.7);
  cursor: pointer;
}

.price-range.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 4px;
  background: #0ABAB5;
}

#amount_one {
  margin-top: 31px;
  background: transparent;
  padding: 0;
  height: auto;
}

.ui-slider-horizontal {
  height: 4px;
  background: #ddd;
}

.price-range.ui-widget.ui-widget-content {
  border: none;
  position: relative;
  top: 15px;
}

.filter-widget .form-group {
  margin-bottom: 20px;
}

.filter-widget .form-group:last-child {
  margin-bottom: 0;
}

.filter-widget .form-group input,
.filter-widget .form-group textarea,
.filter-widget .form-group select {
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;
  border: none;
  border-radius: 5px;
}

.filter-widget .form-group input, .filter-widget .form-group select {
  height: 55px;
}

.filter-widget .form-group select {
  opacity: 0.7;
}

.filter-widget .form-group input::-webkit-input-placeholder, .filter-widget .form-group textarea::-webkit-input-placeholder {
  opacity: 0.7;
}

.filter-widget .form-group input:-ms-input-placeholder, .filter-widget .form-group textarea:-ms-input-placeholder {
  opacity: 0.7;
}

.filter-widget .form-group input::-ms-input-placeholder, .filter-widget .form-group textarea::-ms-input-placeholder {
  opacity: 0.7;
}

.filter-widget .form-group input::placeholder, .filter-widget .form-group textarea::placeholder {
  opacity: 0.7;
}

.filter-widget .form-group textarea {
  height: 120px;
  resize: none;
}

/*-------------------------------
   Blog Section  CSS
-------------------------------*/
.blog-card {
  margin-bottom: 25px;
  background: #fff;
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.07);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.blog-card .blog-img {
  overflow: hidden;
  position: relative;
}

.blog-card .blog-img img {
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.blog-card .blog-info {
  padding: 22px 25px 22px;
}

.blog-card .blog-info .blog-metainfo {
  margin-bottom: 12px;
}

.blog-card .blog-info .blog-metainfo li {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  margin-right: 18px;
  font-size: 14px;
}

.blog-card .blog-info .blog-metainfo li:last-child {
  margin-right: 0;
}

.blog-card .blog-info .blog-metainfo li a {
  font-size: 16px;
  color: #666666;
}

.blog-card .blog-info .blog-metainfo li a:hover {
  color: #0ABAB5;
}

.blog-card .blog-info .blog-metainfo li i {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 14px;
  color: #0ABAB5;
}

.blog-card .blog-info h3 {
  font-size: 22px;
  line-height: 1.6;
  margin-bottom: 12px;
}

.blog-card .blog-info p {
  margin: 0 0 15px;
}

.blog-card .blog-info .link {
  color: #0ABAB5;
  font-family: "Poppins", sans-serif;
}

.blog-card .blog-info .link i {
  margin-left: 4px;
}

.blog-card .blog-info .link:hover {
  color: #0ABAB5;
}

.blog-card .blog-info .link:hover i {
  margin-left: 8px;
}

.blog-card:hover {
  -webkit-box-shadow: 0px 40px 51px -16px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 40px 51px -16px rgba(0, 0, 0, 0.1);
  border-color: transparent;
}

.blog-card:hover .blog-img img {
  -webkit-transform: scale(1.08) rotate(3deg);
  transform: scale(1.08) rotate(3deg);
}

.blog-card:hover .blog-info h3 a {
  color: #0ABAB5;
}

/*-------------------------------
    Blog Details CSS
--------------------------------*/
.post-cat-title {
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 25px;
}

.post-cat-title span {
  font-weight: 600;
  color: #0ABAB5;
}

article p,
.terms-wrap p {
  margin-bottom: 15px;
}

article p strong,
.terms-wrap p strong {
  color: #000;
}

article p a,
.terms-wrap p a {
  color: #0ABAB5;
}

article p a:hover,
.terms-wrap p a:hover {
  text-decoration: underline;
  color: #0ABAB5;
}

article h1,
.terms-wrap h1 {
  font-size: 30px;
  margin-bottom: 15px;
}

article h2,
.terms-wrap h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

article h3,
.terms-wrap h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

article h4,
.terms-wrap h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

article h5,
.terms-wrap h5 {
  font-size: 22px;
  margin-bottom: 15px;
}

article h6,
.terms-wrap h6 {
  font-size: 20px;
  margin-bottom: 15px;
}

article ol,
.terms-wrap ol {
  margin-top: 20px;
  margin-bottom: 25px;
}

article ol li,
.terms-wrap ol li {
  margin-bottom: 15px;
  color: #666666;
  padding-left: 3px;
}

article .content-feature-list,
.terms-wrap .content-feature-list {
  margin: 25px 0 25px;
}

article {
  margin-bottom: 25px;
}

article .content-feature-list li i {
  font-size: 10px;
  top: 6px;
}

.post-navigation {
  margin: 0 0 25px;
}

.post-navigation .next-post,
.post-navigation .prev-post {
  width: 50%;
  display: block;
  font-size: 12px;
  position: relative;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.post-navigation .next-post span,
.post-navigation .prev-post span {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.post-navigation .next-post:hover,
.post-navigation .prev-post:hover {
  color: #0ABAB5;
}

.post-navigation .next-post:hover span,
.post-navigation .prev-post:hover span {
  color: #0ABAB5;
}

.post-navigation .next-post {
  text-align: right;
}

.post-navigation .prev-post {
  text-align: left;
}

.post-metainfo {
  margin-bottom: 18px;
}

.post-metainfo li {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-right: 18px;
  font-size: 14px;
}

.post-metainfo li:last-child {
  margin-right: 0;
}

.post-metainfo li a {
  font-size: 14px;
  color: #666666;
}

.post-metainfo li a:hover {
  color: #0ABAB5;
}

.post-metainfo li i {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 14px;
  line-height: 0.8;
  color: #0ABAB5;
}

.post-img {
  position: relative;
  margin: 0 0 25px;
  display: block;
}

.post-img .blog-date {
  display: inline-block;
  -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  padding: 7px 32px 8px;
  position: absolute;
  top: -10px;
  left: 20px;
  z-index: 1;
  background-color: #0ABAB5;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.post-img .blog-date:hover {
  background: #0ABAB5;
  color: #fff;
}

.wp-block-quote {
  position: relative;
  padding: 35px 35px 35px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin: 30px 0 30px;
}

.wp-block-quote .wp-quote-text {
  width: calc(100% - 100px);
  margin-left: 30px;
  text-align: center;
}

.wp-block-quote .wp-quote-text p {
  font-weight: 500;
  color: #666666;
  font-weight: 500;
  margin-bottom: 0;
}

.wp-block-quote .wp-quote-icon {
  width: 70px;
  height: 100px;
  position: relative;
  z-index: 1;
}

.wp-block-quote .wp-quote-icon i {
  color: #0ABAB5;
  font-size: 68px;
  line-height: 0.8;
  opacity: 0.7;
  margin: 0 auto;
}

.post-meta-option {
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 20px;
  margin-bottom: 25px;
}

.post-tag ul {
  display: inline-block;
}

.post-tag ul li {
  display: inline-block;
}

.post-tag ul li a {
  color: #555;
  font-size: 16px;
  border-radius: 3px;
}

.post-tag ul li a:hover {
  color: #0ABAB5;
}

.post-tag span {
  margin-right: 10px;
  font-weight: 500;
  color: #000;
}

.post-tag span i {
  position: relative;
  top: 1px;
  color: #0ABAB5;
  margin-right: 3px;
}

.post-share {
  display: inline-block;
}

.post-share span {
  font-weight: 500;
  color: #000;
}

.post-share ul {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.post-author {
  padding: 25px;
  margin: 15px 0 30px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.post-author .post-author-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.post-author .post-author-img img {
  border-radius: 50%;
}

.post-author .post-author-info {
  width: calc(100% - 175px);
  margin-left: 25px;
}

.post-author .post-author-info h4 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
}

.post-author .post-author-info p {
  margin: 0 0 15px;
}

.comment-box-wrap {
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.comment-item-wrap .comment-item {
  padding: 0 0 25px 0;
  margin-bottom: 25px;
  border-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.comment-item-wrap .comment-item.reply {
  margin-left: 40px;
}

.comment-item-wrap .comment-item:last-child {
  margin-bottom: 0;
}

.comment-item-wrap .comment-item .comment-author-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  top: 4px;
}

.comment-item-wrap .comment-item .comment-author-img img {
  border-radius: 50%;
}

.comment-item-wrap .comment-item .comment-author-wrap {
  width: calc(100% - 120px);
  margin-left: 20px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name {
  margin: 0 0 9px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name h5 {
  line-height: 1;
  margin: 0 20px 0 0;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-author-name .comment-date {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #888888;
  margin-top: 12px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-text {
  margin: 0 0 10px;
}

.comment-item-wrap .comment-item .comment-author-wrap .comment-text p {
  margin: 0 0 5px;
}

.comment-item-wrap .reply-btn {
  display: inline-block;
  line-height: 1;
  font-weight: 500;
  background-color: #0ABAB5;
  color: #fff;
  padding: 10px 14px;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.comment-item-wrap .reply-btn i {
  position: relative;
  margin-right: 4px;
}

.comment-item-wrap .reply-btn:hover {
  background: #0ABAB5;
  color: #fff;
}

.comment-box-title h4 {
  margin-bottom: 0;
  line-height: 1;
}

.comment-box-title p {
  margin-top: 10px;
  margin-bottom: 0;
}

#cmt-form {
  margin-top: 35px;
}

#cmt-form label {
  color: #888888;
  padding-left: 20px;
}

#cmt-form .comment-form .form-group {
  margin: 0 0 20px;
  position: relative;
}

#cmt-form .comment-form .form-group > label {
  font-weight: 500;
  display: block;
  margin: 0 0 5px;
  padding-left: 0;
  color: #888888;
}

#cmt-form .comment-form .form-group input,
#cmt-form .comment-form .form-group textarea {
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  border-radius: 5px;
  padding: 15px;
  color: #000;
}

#cmt-form .comment-form .form-group input:focus,
#cmt-form .comment-form .form-group textarea:focus {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#cmt-form .comment-form .form-group input {
  height: 60px;
}

#cmt-form .comment-form .form-group textarea {
  height: 145px;
  resize: none;
}

.post-cat-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

.post-cat-title span {
  font-weight: 600;
  color: #0ABAB5;
}

.post-cat-title {
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 25px;
}

.post-cat-title span {
  font-weight: 600;
  color: #0ABAB5;
}

/*-------------------------------
   Agent  Section  CSS
-------------------------------*/
.agent-card {
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.07);
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.agent-card:after {
  position: absolute;
  bottom: -2px;
  left: 50%;
  content: "";
  width: 0%;
  height: 3px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #0ABAB5;
  border-radius: 0 0 5px 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.agent-card .agent-img {
  overflow: hidden;
  position: relative;
  border-radius: 5px 5px 0 0;
}

.agent-card .agent-img img {
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.agent-card .agent-info {
  padding: 22px 25px 22px;
  text-align: center;
}

.agent-card .agent-info h3 {
  font-size: 22px;
  margin-bottom: 5px;
}

.agent-card .agent-info h3 a {
  color: #0ABAB5;
}

.agent-card .agent-info h3 a:hover {
  color: #0ABAB5;
}

.agent-card .agent-info span {
  color: #666666;
}

.agent-card .agent-info .social-profile {
  margin-top: 25px;
  text-align: center;
}

.agent-card .agent-info .social-profile li {
  display: inline-block;
  margin: 0 5px 0;
}

.agent-card .agent-info .social-profile li:last-child {
  margin-bottom: 0;
}

.agent-card .agent-info .social-profile li a {
  width: 42px;
  height: 42px;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.agent-card .agent-info .social-profile li a i {
  color: #0ABAB5;
}

.agent-card .agent-info .social-profile li a:hover i {
  color: #fff;
}

.agent-card:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.agent-card:hover:after {
  width: 85%;
}

.agent-card:hover .team-img:after {
  visibility: visible;
  opacity: 1;
}

.agent-card:hover .team-img .social-profile li {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

/*-------------------------------
    Agent Details  CSS
----------------------------------*/
.agent-details-wrap {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.09);
  padding: 50px 50px 25px;
}

.agent-details-wrap .agent-img {
  border-radius: 5px;
}

.agent-details-wrap .agent-img img {
  border-radius: 5px;
}

.agent-details-wrap .agent-info-wrap .agent-name {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.agent-details-wrap .agent-info-wrap .agent-name h2 {
  font-size: 34px;
  margin: 0 0 5px;
}

.agent-details-wrap .agent-info-wrap .agent-info {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.agent-details-wrap .agent-info-wrap .agent-info li {
  margin-bottom: 15px;
}

.agent-details-wrap .agent-info-wrap .agent-info li:last-child {
  margin-bottom: 0;
}

.agent-details-wrap .agent-info-wrap .agent-info li b {
  color: #000;
  min-width: 100px;
  display: inline-block;
}

.agent-details-wrap .agent-info-wrap .agent-info li span {
  margin-left: 5px;
}

.agent-details-wrap .agent-info-wrap .agent-social span {
  color: #000;
  margin-right: 15px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.agent-details-wrap .agent-info-wrap .agent-social .social-profile li a {
  width: 45px;
  height: 45px;
  background-color: #f9f9f9;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.agent-details-wrap .agent-info-wrap .agent-social .social-profile li a i {
  font-size: 20px;
  color: #0ABAB5;
}

.agent-details-wrap .agent-info-wrap .agent-social .social-profile li a:hover {
  background-color: #0ABAB5;
}

.agent-details-wrap .agent-info-wrap .agent-social .social-profile li a:hover i {
  color: #fff;
}

.agent-details-wrap .agent-desc {
  margin-top: 25px;
}

.agent-details-wrap .agent-desc h4 {
  font-weight: 600;
  margin: 0 0 10px;
}

/*-------------------------------
    Property Details  CSS
----------------------------------*/
.property-details .property-title {
  margin: 0 0 30px;
}

.property-details .property-title h2 {
    font-size: 32px;
    margin: 0 0 12px;
    text-transform: capitalize;
}

.property-details .property-title .property-metainfo li {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.property-details .property-title .property-metainfo li:last-child {
  margin-right: 0;
}

.property-details .property-title .property-metainfo li i {
  font-size: 14px;
  color: #0ABAB5;
  position: relative;
  top: 1px;
  margin-right: 7px;
}

.property-details .property-price {
  color: #0ABAB5;
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-align: right;
  margin: 0;
  position: relative;
  top: 7px;
}

.property-details .property-price span {
  font-weight: 400;
  color: #666666;
  font-size: 15px;
  margin-left: 2px;
  font-family: "Nunito Sans", sans-serif;
}

.property-details .property-img {
  position: relative;
  margin-bottom: 30px;
  border-radius: 5px;
}

.property-details .property-img img {
  border-radius: 5px;
}

.property-details .property-img .property-status {
  position: absolute;
  top: 25px;
  left: 25px;
  display: inline-block;
  background-color: #0ABAB5;
  border-radius: 5px;
  color: #fff;
  padding: 3px 12px;
}

.property-details .property-desc {
  margin-bottom: 25px;
}

.property-details .property-desc:last-child {
  margin-bottom: 0;
}

.property-details .property-desc h4 {
  font-size: 24px;
  margin: 0 0 25px;
}

.property-details .property-desc .property-features li {
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.property-details .property-desc .property-features li:last-child {
  margin-bottom: 0;
}

.property-details .property-desc .property-features li span {
  font-weight: 700;
  color: #666666;
}

.property-details .property-desc .property-video {
  position: relative;
  border-radius: 5px;
}

.property-details .property-desc .property-video img {
  border-radius: 5px;
}

.property-details .property-desc .property-video .play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.property-details .property-desc .floor-plan {
  background-color: #f9f9f9;
  padding: 50px;
}

.property-details .property-desc .floor-plan img {
  display: block;
  margin: 0 auto;
}

.property-details .property-desc .property-annuties li {
  position: relative;
  margin-bottom: 10px;
}

.property-details .property-desc .property-annuties li:last-child {
  margin-bottom: 0;
}

.property-details .property-desc .property-annuties li i {
  position: relative;
  top: 2px;
  color: #0ABAB5;
  margin-right: 7px;
}

.agent-desc .property-review.comment-item-wrap .comment-item .comment-author-img,
.property-desc .property-review.comment-item-wrap .comment-item .comment-author-img {
  top: 2px;
}

.agent-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .ratings,
.property-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .ratings {
  line-height: 1;
}

.agent-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .ratings li,
.property-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .ratings li {
  display: inline-block;
}

.agent-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .ratings li i,
.property-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .ratings li i {
  color: #FFC422;
  font-size: 18px;
}

.agent-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .comment-text,
.property-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .comment-text {
  margin: 0 0 10px;
}

.agent-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .comment-text p,
.property-desc .property-review.comment-item-wrap .comment-item .comment-author-wrap .comment-text p {
  margin: 0;
}

.agent-desc .review-form,
.property-desc .review-form {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 5px;
}

.agent-desc .review-form p,
.property-desc .review-form p {
  margin: 0;
}

.agent-desc .review-form .star-rating,
.property-desc .review-form .star-rating {
  position: relative;
  top: 5px;
  direction: rtl;
  display: inline-block;
  cursor: default;
}

.agent-desc .review-form .star-rating label,
.property-desc .review-form .star-rating label {
  color: #dddddd;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.agent-desc .review-form .form-group,
.property-desc .review-form .form-group {
  margin-bottom: 25px;
}

.agent-desc .review-form .form-group input, .agent-desc .review-form .form-group textarea,
.property-desc .review-form .form-group input,
.property-desc .review-form .form-group textarea {
  width: 100%;
  border: none;
  background-color: #fff;
  padding: 10px 15px;
  border: 1px solid #e9ecef;
}

.agent-desc .review-form .form-group input,
.property-desc .review-form .form-group input {
  height: 57px;
}

.agent-desc .review-form .form-group textarea,
.property-desc .review-form .form-group textarea {
  height: 120px;
  resize: none;
}

.file {
  position: relative;
}

.file input {
  display: none;
}

.file label {
  cursor: pointer;
  outline: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
  background-color: #0ABAB5;
  color: #fff;
  padding: 15px 25px;
}

.review-form .star-rating input[type="radio"] {
  display: none;
}

.review-form .star-rating label:hover,
.review-form .star-rating label:hover ~ label,
.review-form .star-rating input[type="radio"]:checked ~ label {
  color: #f2b600;
}

/*-------------------------------
         Contact CSS
-------------------------------*/
.contact-item {
  padding: 25px;
  border-radius: 0;
  margin: 45px 0 25px;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-align: center;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.09);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.09);
}

.contact-item .contact-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  background-color: #0ABAB5;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin: -70px auto 20px;
}

.contact-item .contact-icon i {
  color: #fff;
  line-height: 0.8;
  font-size: 36px;
  position: relative;
  -webkit-transition: 0.34s;
  transition: 0.34s;
}

.contact-item .contact-info {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.contact-item .contact-info h3 {
  font-size: 24px;
  margin: 0 0 10px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.contact-item .contact-info a,
.contact-item .contact-info p {
  display: block;
  margin: 0;
  line-height: 1.5;
  color: #666666;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.contact-item:hover .contact-icon {
  background-color: #0ABAB5;
}

.contact-item:hover .contact-icon i {
  color: #fff;
}

#contactForm {
  margin-bottom: -10px;
}

#contactForm .form-group {
  margin: 0 0 20px;
}

#contactForm .form-group input,
#contactForm .form-group textarea {
  width: 100%;
  background: #f9f9f9;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
}

#contactForm .form-group input:focus,
#contactForm .form-group textarea:focus {
  border: 1px solid rgba(0, 0, 0, 0.09);
}

#contactForm .form-group input {
  height: 60px;
}

#contactForm .form-group textarea {
  height: 163px;
  resize: none;
  padding-top: 18px;
}

.contact-content .content-title {
  margin-bottom: 30px;
}

.contact-content .social-profile li a {
  width: 50px;
  height: 50px;
  background-color: #f9f9f9;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.contact-content .social-profile li a i {
  color: #0ABAB5;
  font-size: 20px;
}

.btn.disabled {
  opacity: 1;
}

.checkbox {
  display: block;
}

.checkbox.form-check {
  padding-left: 0;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
}

.checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #0ABAB5;
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  position: absolute;
  left: 0;
  top: 5px;
}

.checkbox a {
  color: #0ABAB5;
}

.checkbox.style2 label:before {
  border-radius: 50%;
  border-color: #888888;
}

.checkbox input:checked + label:before {
  border-color: #0ABAB5;
}

.checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 3px;
  width: 9px;
  height: 9px;
  border: none;
  background: #0ABAB5;
}

.checkbox.style2 input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 3px;
  width: 9px;
  height: 9px;
  border: none;
  background: #0ABAB5;
}

.btn.fb span, .btn.twitter span, .btn.linkedin span, .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .social-profile.style1 li a, .social-profile.style2 li a, #signIn .modal-content .modal-header .btn-close,
#signUp .modal-content .modal-header .btn-close, .counter-card-wrap .counter-card .counter-icon, .contact-item .contact-icon, .property-thumb-item, .agent-info-box .agent-name-wrap, .hero-wrap.style3 .hero-content .client-feedback, .property-card .property-info .property-status-wrap, .testimonial-card.style2 .client-info-wrap, .testimonial-card.style3 .client-info-wrap, .counter-card-wrap .counter-card, .post-navigation, .wp-block-quote, .agent-details-wrap .agent-info-wrap .agent-social, .select-lang .navbar-language .dropdown-toggle, .property-card .property-info .property-metainfo, .testimonial-card .quote-wrap .client-info-wrap, .owl-carousel .owl-nav, .pp-post-item, .hero-wrap.style3 .hero-content .property-search-form, .hero-wrap.style3 .hero-content .property-search-form .form-group-wrap, .search-property .property-search-form, .counter-card-wrap, .newsletter-form, .post-author, .comment-item-wrap .comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.btn.fb span, .btn.twitter span, .btn.linkedin span, .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .social-profile.style1 li a, .social-profile.style2 li a, #signIn .modal-content .modal-header .btn-close,
#signUp .modal-content .modal-header .btn-close, .counter-card-wrap .counter-card .counter-icon, .contact-item .contact-icon {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.property-thumb-item, .agent-info-box .agent-name-wrap, .hero-wrap.style3 .hero-content .client-feedback, .property-card .property-info .property-status-wrap, .testimonial-card.style2 .client-info-wrap, .testimonial-card.style3 .client-info-wrap, .counter-card-wrap .counter-card, .post-navigation, .wp-block-quote, .agent-details-wrap .agent-info-wrap .agent-social, .select-lang .navbar-language .dropdown-toggle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

/*---------------------------------
        Footer CSS 
-----------------------------------*/
.footer-wrap {
  position: relative;
  z-index: 1;
  background-color: #f9f9f9;
  overflow: hidden;
}

.footer-wrap .container {
  position: relative;
}

.footer-wrap .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px 0;
}

.footer-wrap .footer-bottom .social-profile {
  text-align: right;
}

.footer-wrap .footer-bottom .social-profile li a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.footer-wrap .footer-bottom .social-profile li a i {
  font-size: 19px;
  color: #0ABAB5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.footer-wrap .footer-widget {
  margin-bottom: 25px;
}

.footer-wrap .footer-widget .footer-logo {
  display: block;
}

.footer-wrap .footer-widget .footer-widget-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
}

.footer-wrap .footer-widget .footer-widget-title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 55px;
  height: 2px;
  background-color: #0ABAB5;
}

.footer-wrap .footer-widget .comp-desc {
  margin: 25px 0 16px;
  padding-right: 10px;
}

.footer-wrap .footer-widget h6 {
  margin: 0  0 15px;
  font-weight: 600;
}

.footer-wrap .footer-widget .select-lang {
  display: inline-block;
  padding: 0;
}

.footer-wrap .footer-widget .select-lang .navbar-language {
  border-radius: 5px;
}

.footer-wrap .footer-widget .select-lang .navbar-language .dropdown-toggle {
  padding: 16px 100px 16px 15px;
  border-radius: 5px;
}

.footer-wrap .footer-widget .select-lang .navbar-language .dropdown-toggle:before {
  top: 16px;
  right: 15px;
}

.footer-wrap .footer-widget .footer-menu li {
  margin-bottom: 15px;
}

.footer-wrap .footer-widget .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-wrap .footer-widget .footer-menu li a {
  position: relative;
  padding-left: 20px;
}

.footer-wrap .footer-widget .footer-menu li a i {
  position: absolute;
  top: 3px;
  left: -4px;
  font-size: 18px;
  line-height: 0.8;
  color: #0ABAB5;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.footer-wrap .footer-widget .footer-menu li a:hover {
  color: #0ABAB5;
  padding-left: 25px;
}

.footer-wrap .footer-widget .footer-menu li a:hover i {
  color: #0ABAB5;
}

.footer-wrap.style1 .footer-widget .select-lang .navbar-language {
  background-color: #DEDEDE;
}

.footer-wrap.style1 .footer-widget .select-lang .navbar-language .dropdown-toggle {
  color: #666666;
}

.footer-wrap.style1 .footer-widget .footer-menu li a {
  color: #666666;
}

.footer-wrap.style1 .footer-widget .footer-menu li a:hover {
  color: #0ABAB5;
}

.footer-wrap.style1 .footer-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-wrap.style1 .footer-bottom .social-profile li a i {
  color: #0ABAB5;
}

.footer-wrap.style1 .footer-bottom .social-profile li a:hover {
  background-color: #0ABAB5;
}

.footer-wrap.style1 .footer-bottom .social-profile li a:hover i {
  color: #fff;
}

.footer-wrap.style2 {
  background-color: #0ABAB5;
  position: relative;
  z-index: 1;
}

.footer-wrap.style2 .footer-shape-one {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.footer-wrap.style2 .footer-widget .footer-widget-title {
  color: #fff;
}

.footer-wrap.style2 .footer-widget .footer-widget-title:after {
  background-color: #fff;
}

.footer-wrap.style2 .footer-widget .comp-desc {
  color: rgba(255, 255, 255, 0.8);
}

.footer-wrap.style2 .footer-widget h6 {
  color: #fff;
}

.footer-wrap.style2 .footer-widget .select-lang .navbar-language .dropdown-toggle {
  background-color: rgba(255, 255, 255, 0.1);
}

.footer-wrap.style2 .footer-widget .footer-menu li a {
  color: rgba(255, 255, 255, 0.8);
}

.footer-wrap.style2 .footer-widget .footer-menu li a i {
  color: #0ABAB5;
}

.footer-wrap.style2 .footer-widget .footer-menu li a:hover {
  color: #0ABAB5;
}

.footer-wrap.style2 .footer-bottom .social-profile li a {
  background-color: rgba(255, 255, 255, 0.09);
}

.footer-wrap.style2 .footer-bottom .social-profile li a i {
  color: #fff;
}

.footer-wrap.style2 .footer-bottom .social-profile li a:hover {
  background-color: #0ABAB5;
}

.footer-wrap.style2 .copyright-text {
  color: rgba(255, 255, 255, 0.8);
}

.footer-wrap.style2 .copyright-text a {
  color: #0ABAB5;
}

.select-lang {
  padding-left: 18px;
  position: relative;
}

.select-lang .navbar-language .dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  padding-right: 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.select-lang .navbar-language .dropdown-toggle:after {
  display: none;
}

.select-lang .navbar-language .dropdown-toggle:before {
  position: absolute;
  top: 2px;
  right: 0;
  content: "\EA4E";
  font-family: "remixicon";
  font-weight: 400;
  border: 0;
  margin-left: 8px;
  font-size: 16px;
}

.select-lang .navbar-language .dropdown-menu {
  margin: 0;
  padding: 5px 0;
  -webkit-box-shadow: 0 9px 54px 0 rgba(32, 32, 32, 0.1);
  box-shadow: 0 9px 54px 0 rgba(32, 32, 32, 0.1);
  left: auto;
  right: 0;
  inset: 54px 0 auto -19px !important;
  border: none;
  border-radius: 0;
  -webkit-transform: translateX(45px) !important;
  transform: translateX(45px) !important;
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.select-lang .navbar-language .dropdown-menu a {
  padding: 5px 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 15px;
}

.select-lang .navbar-language .dropdown-menu a img {
  width: 20px;
  margin-right: 10px;
}

.select-lang .navbar-language .dropdown-menu.show {
  -webkit-transform: translateX(20px) !important;
  transform: translateX(20px) !important;
  opacity: 1;
  pointer-events: all;
}

.comp-map {
  height: 285px;
}

.comp-map iframe {
  width: 100%;
  height: 100%;
}

.copyright-text {
  text-align: left;
  margin: 0;
}

.copyright-text i {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.copyright-text span {
  color: #0ABAB5;
}

.copyright-text a {
  color: #0ABAB5;
  font-weight: 600;
}

.copyright-text a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 18px;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .footer-wrap .footer-widget .comp-desc {
    font-size: 14px;
    padding-right: 10px;
    margin: 12px 0 12px;
  }
  .footer-wrap .footer-widget .footer-menu li {
    margin-bottom: 8px;
  }
  .footer-wrap .footer-widget .footer-menu li a {
    font-size: 14px;
    padding-left: 16px;
  }
  .footer-wrap .footer-widget .footer-menu li a i {
    font-size: 16px;
    top: 3px;
  }
  .footer-wrap .footer-bottom .copyright-text {
    text-align: center;
    margin-bottom: 15px;
  }
  .footer-wrap .footer-bottom .social-profile {
    text-align: center;
  }
  .footer-wrap .footer-bottom .social-profile li a {
    width: 40px;
    height: 40px;
  }
  .footer-wrap .footer-bottom .social-profile li a i {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 20px;
    margin-bottom: 25px;
    padding-bottom: 12px;
  }
  .footer-wrap .footer-widget .comp-desc {
    padding-right: 40px;
    margin: 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-wrap .footer-widget .footer-widget-title {
    font-size: 22px;
  }
  .footer-wrap .footer-widget .comp-desc {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 1199px) {
  .footer-wrap .footer-top .footer-widget .footer-widget-title {
    margin-bottom: 18px;
  }
}

@media only screen and (min-width: 1200px) {
  .footer-wrap .footer-widget .comp-desc {
    padding-right: 30px;
  }
  .footer-wrap .footer-widget .footer-widget-title {
    margin-top: 15px;
  }
  .footer-wrap .footer-widget .comp-map {
    margin-top: 15px;
  }
}

/*-------------------------------
    Buy Now Btn
-------------------------------*/
.buy-now-btn {
  right: 20px;
  z-index: 99;
  top: 50%;
  position: fixed;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block; 
  color: #fff;
  background-color: #82b440;
  padding: 10px 20px 10px 42px;
  -webkit-box-shadow: 0 1px 20px 1px #82b440;
          box-shadow: 0 1px 20px 1px #82b440;
  font-size: 15px;
  font-weight: 600;
}

.buy-now-btn img {
  top: 50%;
  left: 20px;
  width: 15px;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.buy-now-btn:hover {
  color: #fff;
  background-color: #94be5d;
}
.footer-wrap.blck_footer{
  background-color:#111;
}
.theme-light .footer-logo .logo-light, .theme-light .navbar-brand .logo-light {
    display: block !important;
    width: 100%;
    height:45px;
}
.theme-light .footer-logo .logo-light, .theme-light .navbar-brand .logo-light {
    display: block !important;
    width: auto;
}
.gray_bg_updated{
  background-color:#f0f0f0b8;
}
.adv-img img{
  height: 80px;
  width: auto;
}
.border_remove_hero{
  border-radius:0px;
}
.bg-white_image{
  background-color:#fff;
}
.explore_left .city-card img {
    width: 100%;
    height: 625px;
}
.explore_right .city-card img {
    height: 300px;
    width: 100%;
}
.login_bg{
  background-color:#f0f0f0;
}
.login_bg .col-md-6 {
    padding-left: 0px;
    padding-right: 0px;
}
.login-content .user-form input[type="radio"] {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 0px;
}
.help_center_form {
    position: relative;
    width: 60%;
    margin: auto;
}
.help_center_form input {
    width: 100%;
    height: 64px;
    background-color: #f9f9f9;
    border: none;
    padding: 10px 115px 10px 47px;
    border-radius: 10px;
}
.help_center_form i {
    position: absolute;
    top: 23px;
    left: 20px;
    color: #002172;
}
.help_center_form button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #EC3323;
    color: #fff;
    border: none;
    padding: 10px 25px;
    border-radius: 10px;
}
.breadcrumb-title p{
  color:#fff;
}
.container_chat_boat {
    margin: 0 auto;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.people-list {
    width: 30%;
    float: left;
    color: #fff;
    background: #03605e;
}
.profile_cover_image img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.people-list .search {
    padding: 20px;
    position: relative;
}
.people-list input {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: #000;
    background: #f2f5f8;
    width: 100%;
    font-size: 14px;
}
.people-list .fa-search {
    position: absolute;
    color: #000;
    right: 30px;
    top: 34px;
}
.people-list ul {
    padding: 20px;
    list-style: none;
    height: 686px;
}
.chat .chat-history .message-data {
    margin-bottom: 15px;
}
.chat-history ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.align-right {
    text-align: right;
}
.people-list ul li {
  padding-bottom: 20px;
}
.people-list img {
    float: left;
    border-radius: 100%;
}
.grey_bg_wrapper{
  background-color:#f0f0f0;
}
.people-list .about {
    float: left;
    margin-top: 0;
}
.people-list .about {
  padding-left: 8px;
}
.people-list .status {
    color: #92959E;
    font-size: 14px;
}
.chat {
    width: 70%;
    float: left;
    background: #ffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #434651;
}
.chat .chat-header {
    padding: 20px;
    border-bottom: 2px solid #fbf4f4;
    display: flex;
    align-items: center;
}
.chat .chat-header img {
    float: left;
    width: 55px;
    height: 55px;
}
.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}
.chat .chat-header .chat-with {
    font-weight: bold;
    font-size: 17px;
}
.chat .chat-header .chat-num-messages {
  color: #92959E;
}
.chat .chat-header .fa-star {
  float: right;
  color: #D8DADF;
  font-size: 20px;
  margin-top: 12px;
}
.chat .chat-history {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    overflow-y: auto;
    height: 525px;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}
.chat .chat-history .message-data-time {
    color: #a8aab1;
    padding-left: 6px;
    font-size: 13px;
}
.chat .chat-history .message {
    color: #000;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 30px;
    width: 50%;
    position: relative;
}
.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #edf3ee;
    border-width: 10px;
    margin-left: -10px;
}
.chat .chat-history .my-message {
    background: #edf3ee;
}
.chat .chat-history .other-message {
    background: #f0f0f0;
}
.chat .chat-history .other-message:after {
    border-bottom-color: #f0f0f0;
    left: 93%;
}
.chat .chat-message {
  padding: 30px;
}
.chat .chat-message textarea {
    width: 100%;
    border: none;
    padding: 10px 20px;
    font: 14px/22px "Lato", Arial, sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    resize: none;
    background: #f0f0f0;
}
.chat .chat-message .fa-file-o, .chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
.chat .chat-message button {
    float: right;
    color: #234b40;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
}
.chat .chat-message button:hover {
  color: #75b1e8;
}

.online, .offline, .me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86BB71;
}

.offline {
  color: #E38968;
}

.support_data .adv-card {
    margin: 0 0 65px;
}
.support_data .adv-card .adv-img img {
    height: 235px;
    border-radius: 5px;
    width: 100%;
}
.support_data .adv-card .adv-img {
    position: relative;
    z-index: 1;
    margin-bottom:5px;
}
.support_data .adv-card .adv-info h3 {
    font-size: 18px;
}
.property_heightligh_box {
    background-color: #b8dddc54;
}
.property_heightligh_box {
    background-color: #b8dddc54;
    padding: 20px;
    border-radius: 4px;
}
.property_heightligh_box .list-style li i{
    color: #0ABAB5;
    margin-right: 7px;
}
.search_sidebar, .category_sidebar {
    background-color: #b8dddc54;
    padding: 20px;
    border-radius: 4px;
}
.search_sidebar .form-group input, .search_sidebar .form-group select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 50px;
    background-color:#fff;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.search_sidebar .form-group .btn{
  width:100%;
}
.gallery { 
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 250px 250px;
  grid-auto-flow: dense;
}

.gallery .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 480px) {
  .gallery .item:first-child {
    grid-area: 1 / 1 / span 2 / span 2;
  } 
  
/*  .gallery .item:nth-child(3n) {
    grid-column: span 2;
  }*/
}
.support_data .adv-card .adv-img:after {
  display: none;
}
.profile_box {
    background: #fff;
    border: 1px solid #D3D3D3;
    padding: 40px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    text-align: center;
    margin-top: -75px;
}
.profile_wrapper {
    background: #f0f0f0c4;
    padding-bottom: 50px;
}
.profile_wrapper .profile_pic img {
    width: 100px;
    height: 100px;
    border: 3px solid #234b406b;
    object-fit: cover;
    object-position: top;
    border-radius: 100%;
}
.profile_content h3 {
    font-size: 19px;
    margin-top: 10px;
    margin-bottom:0px;
}
.profile_content p{
    font-size: 16px;
}
.about_text {
    margin-top: 15px;
}
.profile_about_seciton {
    margin-top: 30px;
}
.profile_about_seciton h3{
  font-size: 20px;
}
.categories_list {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.categories_list li{
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 700;
}
.tour_booking_form_box {
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
}

#tour_bookking_form_item .form-group {
    margin-bottom: 30px;
}

#tour_bookking_form_item {
    padding-top: 25px;
}

.booking_tour_form {
    margin-bottom: 30px;
}

.booking_tour_form_submit a {
    margin-top: 15px;
}

.coupon_code_area_booking {
    padding-top: 30px;
}

.coupon_code_submit {
    padding-top: 20px;
}

.tour_booking_amount_area ul {
    padding-top: 15px;
}

.tour_booking_amount_area ul li {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
    font-weight: 500;
    font-size: 16px;
}

/*.tour_booking_amount_area ul li:last-child {
    border-bottom: 1px solid #dadada
}*/

.tour_bokking_subtotal_area {
    padding-top: 15px;
}

.tour_bokking_subtotal_area h6 {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 105px;
    color: #fff;
}
.coupon_add_area {
    padding-top: 15px;
    border-bottom: 1px solid #dadada;
    padding-bottom: 15px;
}

.coupon_add_area h6 {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.remove_coupon_tour {
    font-size: 14px;
    font-style: italic;
    font-weight: 400 !important;
    color: var(--main-color);
    cursor: pointer;
}

.total_subtotal_booking {
    padding-top: 15px;
}

.total_subtotal_booking h6 {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    color: #fff;
}
#payment_checked .form-check-label {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
/*    margin-bottom: 13px;*/
    cursor: pointer;
    color: #000;
}
.tour_details_right_boxed {
    background: #caefeeb3;
    border-radius: 10px;
    padding: 25px 20px 35px 20px;
    color: #fff;
    margin-bottom: 15px;
}
.first_child_padding_none ul li:first-child {
    padding-top: 0;
}

.tour_details_right_box_heading h3 {
    font-weight: 500;
    font-size: 22px;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
    display: inline-block;
    color: #fff;
}

.valid_date_area {
    display: flex;
    align-items: center;
}

.valid_date_area_one {
    padding-right: 40px;
}

.valid_date_area_one h5 {
    font-weight: 500;
    padding-bottom: 5px;
    font-size: 18px;
    margin-bottom: 0px;
    color: #fff;
}
.valid_date_area_one p {
    color: #222;
}

.tour_package_details_bar_list {
    padding-top: 20px;
}

.tour_package_details_bar_list h5 {
    font-weight: 500;
    padding-bottom: 0;
    display: inline-block;
    margin-bottom: 0;
    color: #000;
}

.tour_package_details_bar_list ul li {
    padding-top: 15px;
    color: var(--paragraph-color);
    border-bottom: 1px solid #cccccc38;
    padding-bottom: 10px;
}
.tour_package_details_bar_list ul li:last-child{
  border-bottom: none;
}
.top_services {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}
.services_bottom {
    color: #4d4b4b;
    font-weight: normal;
    font-size: 13px;
    padding-right: 50px;
    padding-left: 20px;
}
.top_services_right {
    min-width: 30px;
}
.top_services_right img {
    height: 30px;
    width: 30px;
    object-fit: contain;
}
.tour_package_details_bar_list ul li i {
    color: var(--black-color);
    font-size: 6px;
    padding-right: 7px;
}

.tour_package_details_bar_price {
    padding-top: 20px;
}

.tour_package_details_bar_price h5 {
    font-weight: 500;
    display: inline-block;
    color: #fff;
    margin-bottom: 0;
    padding-bottom: 0px;
}

.tour_package_bar_price {
    display: flex;
    align-items: center;
    padding-top: 15px;
}

.tour_package_bar_price h6 {
    font-size: 16px;
    font-weight: 500;
}

.tour_package_bar_price h3 {
    padding-left: 10px;
    font-size: 22px;
    font-weight: 500;
    color: var(--main-color);
}

.tour_package_bar_price h3 sub {
    color: var(--paragraph-color);
    font-weight: 400;
    bottom: 0;
    font-size: 14px;
}

.tour_select_offer_bar_bottom button {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.select_offer_modal.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;
    z-index: 999999;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.travel_date_side .form-control {
    border: 1px solid var(--black-color);
    margin-top: 10px;
}

.select_person_side {
    padding-top: 25px;
}

.select_person_side h3 {
    font-size: 18px;
    font-weight: 500;
}

.select_person_item {
    padding-top: 20px;
    border-bottom: 1px solid #00000030;
    padding-bottom: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select_person_left h6 {
    font-size: 16px;
    font-weight: 500;
}

.select_person_left p {
    font-size: 12px;
    font-weight: 500;
}

.select_person_right button {
    border: 1px solid #d6d6d6;
    background: transparent;
    font-size: 10px;
    transition: var(--transition);
}

.select_person_right button:hover {
    background: var(--main-color);
    color: var(--white-color);
}

.select_person_right span {
    font-size: 14px;
    padding: 0 4px;
}

.write_spical_not {
    padding-top: 30px;
}


.write_spical_not textarea {
    height: 100%;
    border: 1px solid var(--black-color);
    margin-top: 10px;
}

.write_spical_check {
    padding-top: 10px;
}

.main_spical_check p {
    font-size: 14px;
}

.main_spical_check p span {
    color: var(--main-color);
}

.proceed_booking_btn a {
    border-radius: 0;
}

.tour_details_heading_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tour_details_top_heading h5 {
    padding-top: 15px;
    font-size: 16px;
}

.tour_details_top_heading_right h4 {
    color: var(--main-color);
}

.tour_details_top_heading_right h6 {
    font-size: 16px;
    color: var(--main-color);
    padding-top: 5px;
}

.tour_details_top_heading_right p {
    padding-top: 2px;
    font-size: 14px;
}

.tour_details_top_bottom {
    margin-top: 25px;
    border-top: 1px solid #eeeaea;
    padding-top: 13px;
    border-bottom: 1px solid #eeeaea;
    padding-bottom: 13px;
    display: flex;
    justify-content: space-between;
}

.toru_details_top_bottom_item {
    display: flex;
    align-items: center;
}

.tour_details_top_bottom_icon {
    font-size: 30px;
    padding-right: 10px;
}

.tour_details_top_bottom_text h5 {
    font-weight: 500;
}

.tour_details_top_bottom_text p {
    font-size: 14px;
}

.tour_details_img_wrapper {
    margin-top: 40px;
    display: block;
}

.tour_details_img_wrapper .slider-nav {
    margin-top: 20px;
}

.tour_details_boxed {
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 20px 20px;
    margin-top: 30px;
}

.heading_theme {
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
}

.tour_details_boxed_inner p {
    padding-bottom: 15px;
}

.tour_details_boxed_inner ul li {
    padding-bottom: 15px;
    color: var(--paragraph-color);
    display: flex;
}

.tour_details_boxed_inner ul li i {
    color: var(--black-color);
    font-size: 6px;
    padding-right: 7px;
    padding-top: 6px;
}

.tour_details_boxed_inner .accordion-button {
    padding: 0;
    font-weight: 600;
    font-size: 18px;
}

.tour_details_boxed_inner .accordion-item {
    border: none;
    border-radius: 0;
    padding-bottom: 15px;
    width: 100%;
}

.tour_details_boxed_inner .accordion-button:not(.collapsed) {
    color: var(--main-color);
    background-color: #fff;
    box-shadow: none;
}

.tour_details_boxed_inner .accordion-button:focus {
    z-index: 3;
    border-color: #fff;
    outline: 0;
    box-shadow: none;
}

.tour_details_boxed_inner .accordion-body {
    padding: 0;
}
.tour_package_details_bar_list ul {
    list-style: none;
    padding: 0px;
    font-weight: 600;
    color: #000;
}
#tour_bookking_form_item .form-control {
    padding: 10px;
}
.black_background{
  background-color:#111;
}
.tour_booking_amount_area ul {
    padding: 0px;
}
.full-btn{
  width:100%;
}
.help_tut{
    display: inline-block;
    padding: 5px 12px 4px;
    border-radius: 5px;
    background-color: #0ABAB5;
    color: #fff;
}

.logo-light {
    transition: fill 0.3s ease;
}
.logo-light:hover{
   fill: #ff0000;
}
#writ_review{
      z-index: 99999;
}
#writ_review .ri-close-line:before{
  display: none;
}
.logo-light {
    height: 45px;
}
.slick-slide > div {
    margin-left: 10px;
    margin-right: 10px;
}
.social-icon {
    width: 100%;
    font-size: 20px;
    color: #fff;
    display: flex;
}
.social-icon a {
    font-size: 20px;
    color: white;
    height: 50px;
    width: 50px !important;
    background: #0ABAB5;
    border-radius: 60%;
    margin: 0px 5px;
    border: none;
    cursor: pointer;
    text-align: center;
    line-height: 50px;
}
.social-icon a:hover{
  background-color:#222;
}
.about_img img {
    width: 100%;
}
.first_city_name img {
    width: 100%;
    height: 626px;
}
.property-card.style4 .property-img img {
    height: 250px;
    object-fit: cover;
}


/* dropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 185px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 5px 16px;
    text-decoration: none;
    display: block;
    font-size: 15px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.show {
  display: block;
}
.profile_name:hover{
  cursor: pointer;
}
.profile_name {
    display: flex;
    align-items: center;
    padding: 2px 0px;
}
#tour_bookking_form_item input[type="radio"] {
    margin-right: 5px;
    margin-left: 3px;
}
.profile_name img {
    height: 50px;
    margin-right: 7px;
    width: 50px;
    background-size: cover;
    border-radius: 100%;
    object-fit: cover;
    object-position: top;
}
.profile_name i {
    font-size: 22px;
}
.profie_data {
  text-align: left;
  margin-top: 25px;
  word-wrap: break-word;
}
.profie_data p {
    margin-bottom: 3px;
    padding-left: 26px;
    position: relative;
}
.profie_data p i {
    font-size: 16px;
    color: #0abab5;
    position: absolute;
    left: 0px;
}
.profile_about_section h3 {
    margin-top: 20px;
    font-size: 20px;
}
.profile_section {
    background: #ffff;
    padding: 15px;
    border-radius:7px;
    margin-bottom: 15px;
}
.profile_section strong {
    color: #333;
}
.mb-30{
  margin-bottom: 30px;
}
.tour_booking_form_box.property-desc{
  background: #f0f0f0;
}
.center_btn{
  text-align: center;
  margin-top: 30px;
}
.heightlihg_text{
  color:#0ABAB5;
}
.with_checkbox_list input[type="checkbox"]{
  margin-right:7px;
}
.tour_details_right_boxed .with_checkbox_list input[type="checkbox"] {
    margin-right: 7px;
    position: absolute;
    top: 6px;
    left: 0px;
}

/* Container for the checkbox and label */
.top_services_left {
  display: flex;
  align-items: center;
}

.custom-checkbox {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.t_amount {
    padding-left: 20px;
}
/* Create a custom checkmark box */
.custom-checkbox .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #9a99995c;
    margin-left: -25px;
}

/* When the checkbox is checked, change the background color */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #28a745; /* Green color for the checked state */
}

/* Checkmark indicator (the tick) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark (the tick) */
.custom-checkbox .checkmark:after {
    left: 5px;
    top: 0px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


.top_services_left {
    padding-left: 20px;
    position: relative;
}
.checkout_property {
    display: flex;
    column-gap: 10px;
}
.checkout_property_img img {
    width: 140px;
    height: 90px;
    object-fit: cover;
}
.checkout_property_content p {
    color: #000;
    margin-bottom: 0px;
}
.property-price-chekout {
    color: #0ABAB5 !important;
}
.coupon_code {
    margin-top: 10px;
    padding: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.coupon_heighlight{
  color:#f00;
}
.coupon-success-message {
    color: green;
    margin-top: 10px;
}
.coupon-error-message {
    color: red;
    text-transform: capitalize;
}
.full_width .btn{
  width:100%;
}
.payment_form .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.payment_form label{
  font-weight:700;
  color:#000;
}
.payment_form .form-group {
    margin-bottom: 15px;
}
.mb_15_btn{
  margin-bottom: 10px;
}
.input-group-addon i {
    font-size: 21px;
}
.order_history_table thead tr td{
  font-weight:700;
  background-color:#f0f0f0;
}
.no_order{
  color:#f00;
}
.property_img_order {
    height: 50px;
    width: 75px;
    object-fit: cover;
}
.green_success{
  color:green;
}
.red_success{
  color:red;
}
.people-list img {
    float: left;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    object-fit: cover;
}
.people-list ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 0px;
}
.people-list ul li a {
    color: #fff;
}
.clearfix.our-messages {
    clear: both;
    float: right;
}
.clearfix.other-messages {
    float: left;
    clear: both;
}
.our-messages > .message.our-messages{
    background: #edf3ee;
}  
.other-messages > .message.other-messages {
    background: #d9f3f2;
    width: 50%;
}
.chat .chat-history .other-messages > .message.other-messages:after {
    bottom: 100%;
    right: 9%;
    left: 25px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #d9f3f2;
    border-width: 10px;
    margin-left: -10px;
}    
.user_list {
    max-height: 712px;
    overflow: auto;
}
.no-user-selected {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    overflow-y: auto;
    height:714px;
}
.head_section strong {
    color: #000;
}
.head_section{
  margin-bottom: 0px;
}
.chat_btn {
    text-align: left;
}
.chat_btn .btn {
    padding: 4px 15px;
}
.social {
    color: #fff;
}
.testimonial-slider-two .client-quote {
    min-height: 125px;
}

.about_img img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}
.social_icon_login{
  justify-content: center;
}
.login-content p a {
    color: #0ABAB5;
}
h2.no_properties {
    font-size: 17px;
    color: #f00;
}
.no_property_name{
  color:#f00;
}
.map_area_bottom {
    margin-top: 15px;
}
.map_area_bottom iframe{
  width: 100%;
}
.payment_details p {
    color: #000;
}
.payment_details p span{
  float: right;
  color:#0ABAB5;
}
.payment_form_options{
  margin-top: 15px;
}
.payment_form_options .heading_theme {
    font-size: 22px;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.pay_now_full_width button{
  width:100%;
}
.people-list ul li.selected {
  background: #53a2b226;
  border-radius: 5px;
}
.our-messages .message-data.our-messages {
    text-align: right;
}
.our-messages .message.our-messages {
    float: right;
}
.our-messages .message.our-messages:after {
    left: auto;
    right: 25px;
}
/* CSS for Stripe CardElement */
.stripe-card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

/* Style the card number input */
.stripe-card-element .CardNumberElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Style the card expiration date input */
.stripe-card-element .CardExpiryElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Style the card CVC input */
.stripe-card-element .CardCvcElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Style the card error message */
.stripe-card-element .StripeElement--error {
  border-color: #ff3860 !important;
}

/* Style the card input in focus */
.stripe-card-element .StripeElement--focus {
  border-color: #0366d6 !important;
}

/* Style the card input when it's valid */
.stripe-card-element .StripeElement--valid {
  border-color: #4caf50 !important;
}

/* CSS for Stripe CardElement */
.stripe-card-element {
    width: 100%;
    margin-bottom: 15px;
}
/* Style the card number input */
.stripe-card-element .CardNumberElement {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

/* Style the card expiration date input */
.stripe-card-element .CardExpiryElement {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

/* Style the card CVC input */
.stripe-card-element .CardCvcElement {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

/* Style the card error message */
.stripe-card-element .StripeElement--error {
  border-color: #ff3860 !important;
}

/* Style the card input in focus */
.stripe-card-element .StripeElement--focus {
  border-color: #0366d6 !important;
}

/* Style the card input when it's valid */
.stripe-card-element .StripeElement--valid {
  border-color: #4caf50 !important;
}
.tour_booking_form_box tr td {
    text-transform: capitalize;
}
.table_btn .btn {
    padding: 5px 10px !important;
    font-size: 13px;
    min-width: 90px;
}















/* Card body padding and text styles */
.tour-card-body {
  padding: 10px;
}

.tour-card-title {
  font-size: 21px;
  font-weight: 600;
  color: #000;
  margin: 0 0 .6rem;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.tour-card-text {
  font-size: 17px;
  margin-bottom: 25px;
  font-weight: normal;
}

.tour-list-inline-item {
  padding-right: 8px;
}

li.tour-list-inline-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
li.tour-list-inline-item i {
  font-weight: 400;
  color: #676767;
  font-size: 35px;
}
.tour-price {
  font-size: 25px;
  margin-bottom: 0;
  text-align: right;
}
.pricandbook {
  display: grid;
  justify-content: center;
  align-items: normal;
  height: 100%;
  min-width: 100%;
  width: 100%;
}
h5.tour-price span {
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
  color: #444242;
} 
.pric-w small {
  text-align: right;
  display: block;
  font-style: italic;
  color: #000;
}
.pric-w {
  margin-bottom: 40px;
}
/* Button styling */
button.tour-btn {
  padding: 0px;
  color: #0ABAB5;
  font-size: 20px;
  border: none;
  cursor: pointer;
  background: transparent;
  font-weight: bold;
}
.pricandbook button.btn.btn-danger.btn-sm.tour-btn:focus {
  background: transparent;
  color: #0ABAB5;
}

.pricandbook .btn i {
  position: relative;
  top: 3px;
  font-size: 32px;
  margin-left: 7px;
  transition: all 0.4s ease-in-out;
  font-weight: normal;
  margin-top: -2px;
}
.pricandbook button.btn.btn-danger.btn-sm.tour-btn {
  display: flex;
}
button.tour-btn:hover {
  background: transparent;
  color: #0ABAB5;
}
ul.tour-list-inline {
  display: flex;
  gap: 20px;
}
.service-selected {
  background: #0ABAB5;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  font-size: 28px;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
}
/* List styling */
.tour-list-inline {
  padding-left: 0;
  list-style: none;
  margin-bottom: 5px;
}

/* Card layout adjustments */
.tour-detail-card {
  background-color: #ffffffb3;
  border-radius: 10px;
  padding: 0px;
  border: 1px solid gainsboro;
}

.tour-detail-card img {
  height: 215px;
  width: 100%;
  object-fit: cover;
  /* border-radius: 10px 0 0 10px; */
}

.tour-card-body {
  padding: 25px 0px 10px 20px;
}

/* Checkbox positioning and style */
.top-right-checkbox {
  top: 10px;
  left: 10px;
}
.pricandbook {
  padding: 25px 0px 0px 0px;
}

.position-absolute {
  position: absolute;
}

/* Adjust the checkbox size */
.top-right-checkbox input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #0ABAB5; /* Custom checkbox color */
}
.tour_detail_right_sidebar.vds {
  background: #ffffffb3;
  border-radius: 10px;
  padding: 20px 10px;
  margin-bottom: 50px;
  border: 1px solid gainsboro;
}
.tour_details_right_sidebar_wrapper.vds {
  padding-top: 54px;
}
li.tour-list-inline-item img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  max-height: 40px !important;
  min-height: 40px !important;
}
.tre i {
  display: none;
}
.tour_details_right_boxed.eraw .tour_package_details_bar_list {
  padding-top: 0px;
}
.tour_details_right_boxed.eraw .tour_package_details_bar_list ul {
  margin-bottom: 0px;
}
.tour-detail-card.mb-3.selected-q {
  border: 2px solid #0ABAB5;
}
.learn-button a span {
  display: block;
  width: 100%;
  text-align: center;
  word-wrap: break-word;
}
.learn-button a {
  height: 89px;
  width: 180px;
  background: #0ABAB5;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px 15px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.learn-button a:before {
  left: 0;
  bottom: 0;
  height: 0;
  content: '';
  width: 100%;
  display: block;
  position: absolute;
  background: #333333;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all 600ms cubic-bezier(0.785,0.135,0.150,0.860);
  transition: all 600ms cubic-bezier(0.785,0.135,0.150,0.860);
}
.learn-img-sc {
  position: relative;
}
.learn-button {
  position: absolute;
  bottom: -7%;
  left: 50%;
  transform: translateX(-50%);
}
.learn-button a:hover::before, .learn-button a:focus::before {
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
}
.learn-button a span:last-child {
  position: relative;
  z-index: 99;
}
.learn-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
/* .learn-more .row.swq .col-md-4 {
  padding: 0px 50px;
} */
.d-flex.ads a.btn {
  width: 50%;
}
.learn-inner h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.private-card-img {
  height: 300px;
  object-fit: cover;
}
.custom-card-img {
  height: 300px;
  object-fit: cover;
}
/* .private-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
} */
.card-body .list-unstyled {
  margin-bottom: 40px;
}
.custom-card {
  border: none;
  /* border-radius: 10px; */
  /* box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1); */
  height: 100%;

}
h2.ci-title {
  font-size: 48px;
  margin-bottom: 30px;
}
.card-body .list-unstyled i {
  color: #000;
  margin-right: 15px;
  font-size: 21px;
}
.card-body .list-unstyled li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ads {
  gap: 25px;
}
section.business-section .row {
  align-items: center;
  padding: 0px 0px;
}
.contact-card-img{
  height: 500px;
}
.business-card.text-right {
  text-align: right;
}
section.workspace-section {
  background: #f0f0f0b8;
}
.property-wrap{
  background-color: #f0f0f0b8;
}
.property-info {
  background: #fff;
}



.info-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
.info-box h5 {
  margin-bottom: 15px;
}
.info-box button {
  margin-right: 10px;
}
.small-text {
  font-size: 0.9rem;
}
p.sqw {
  display: flex;
  gap: 10px;
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.contact-form-sc{
  background-color: #f0f0f0b8;
}
ul.list-unstyled.fgew {
  display: flex;
  flex-wrap: wrap;
  column-gap:20px;
}
ul.list-unstyled.fgew li {
  width: 48%;
}
.container.form-container.login-content {
  padding: 0px;
}
.container.form-container.login-content .form-section {
  border-right: 1px solid #00000017;
}
.col-md-6.info-section {
 padding-left: 50px;
}
.col-md-6.form-section {
  padding-right: 50px;
}
a.btn.btn-outline-primary {
  background: #fff;
  color: #000;
  font-size: 13px;
  border-radius: 38px;
  border: 1px solid #00000029;
}
.btn-scf {
  display: flex;
  gap: 10px;
}

.learn-more .line--subtitle-vision {
  width: calc(100% - 90px);
}
.learn-more .subtitle {
  padding-left: 30px;
  margin-bottom: 10px;
}
.subtitle--small {
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  margin-bottom: 40px;
  color: #0ABAB5;
}
.learn-more .line--subtitle {
  margin-right: 0;
}
.line--subtitle {
  width: 150px;
  height: 1px;
  margin: 0 15px;
  background: #0ABAB5;
  vertical-align: middle;
  display: inline-block;
}
.login-bg.bg-f.sdf {
  background: #f0f0f0;
}
.login-bg.bg-f.sdf {
  padding: 45px 45px;
  /* padding-left: 0; */
}
.property-details-wrap .login-content {
  padding-top: 45px;
  padding-bottom: 45px;
}
.login-bg.bg-f.sdf h2 {
  font-size: 40px;
  margin-bottom: 50px;
}
.login-bg.bg-f.sdf hr {
  width: 50px;
  height: 3px;
  border-top: solid;
  color: #0ABAB5;
  opacity: 1;
  margin-bottom: 50px;
}
.login-bg.bg-f.sdf h5 {
  color: #0ABAB5;
  margin-bottom: 50px;
}
.login-bg.bg-f.sdf {
  border-right: 1px solid #00000024;
  height: 100%;
}
/* .trova {
  max-width: 400px;
} */

.ervcx{
  padding-left: 100px;
}
section.business-section.wer .row {
padding-left: 0 !important;
padding-right: 0 !important;
}
.bg-alight {
  background-color: #f0f0f0;
  background-image: url(https://www.regus.com/icons/globalTablet.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 140%;
}
/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .tour-card-title {
    font-size: 20px;
  }

  .tour-price {
    font-size: 20px;
  }

  button.tour-btn {
    padding: 0px 10px;
    font-size: 15px;
  }
  .pricandbook .btn i {
    font-size: 28px;
  }
  ul.tour-list-inline {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}
li.tour-list-inline-item i {
  font-weight: 400;
  color: #676767;
  font-size: 25px;
}
li.tour-list-inline-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  color: #000;
  font-weight: 600;
}
.pricandbook {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  margin-top: 10px;
  border-top: 1px solid #0000001c;
  padding-top: 8px;
}
.pricandbook button.btn.btn-danger.btn-sm.tour-btn {
  display: flex;
  padding: 10px 0px;
}
.pric-w {
  margin-bottom: 0px;
}
.pricandbook {
  padding-bottom: 20px;
}
.login-bg.bg-f.sdf {
  padding: 30px 20px;
}
.login-bg.bg-f.sdf h2 {
  font-size: 30px;
  margin-bottom: 50px;
}
.learn-inner {
  margin-bottom: 60px;
}
section.business-section .row {
  align-items: center;
  padding: 0px 0px;
}
h2.ci-title {
  font-size: 26px;
  margin-bottom: 25px;
}
section.business-section.pb-100.sdfwv .row {
  flex-flow: column-reverse;
}
ul.list-unstyled.fgew li {
  width: 100%;
}
.card-body .list-unstyled {
  margin-bottom: 0;
}
.container.form-container.login-content .form-section {
  padding-right: initial;
  padding-left: initial;
  border-right: 0px solid !important;
}
.col-md-6.info-section {
  padding-left: initial;
}
.container.form-container.login-content {
  padding: 0px 30px !important;
}
.col-md-6.info-section {
  margin-top: 30px;
}
.btn-scf {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.btn-scf .btn.style3{
  width: 100%;
}
}



button.btn-close {
  position: absolute;
  right: 0;
  top: -17px;
  right: -15px;
  font-size: 22px;
}
section#workspace-s {
  padding: 40px 0px;
}
@media (min-width: 1200px) {
  .modal-xl {
      max-width: 1260px;
  }
}
.modal.fade.show {
  background: #000000b5;
}
button.btn-close {
  opacity: 1;
}

button.btn-close {
  background-color: #0ABAB5;
  color: #fff;
}
ul.list-unstyled.fgew img {
  width: 20px;
  /* height: 20px; */
}
ul.list-unstyled.fgew li {
gap: 15px;
}
section.property-details-wrap.pb-75.asd {
  background: #f0f0f0;
}
.btn-scf {
  flex-wrap: wrap;
}